/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import React, {
  useState,
  useEffect,
  useCallback
} from "react";
import PropTypes from "prop-types";

import {
  uploadGstDetails
} from "api";

import env from "env";

import {
  handleApiError,
  WealthyValidations,
  triggerToast
} from "helpers";
import {
  RudderStackEvent
} from "types";
import {
  Analytics,
  checkIsWebView
} from "utils";

import PartnerGst from "./PartnerGst";

const propTypes = {
  kycData: PropTypes.object.isRequired,
  updateStatus: PropTypes.func.isRequired,
  skipPartnerStep: PropTypes.func.isRequired,
};

const PartnerGstContainer = ({
  kycData,
  updateStatus,
  skipPartnerStep
}) => {
  const [formData, setFormData] = useState({
    "gstNo": kycData?.prefill?.gstNo
  });

  const [isRedirectLoading, setRedirectLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSkipButton, setShowSkipButton] = useState(true);

  useEffect(() => {
    if (!kycData?.canUserSkip) {
      setShowSkipButton(false);
    }
  }, [kycData]);

  const validateFields = () => {
    const validatedGst = WealthyValidations.validateFormField.gstGeneric(formData.gstNo);

    if (!validatedGst) {
      triggerToast("Gst is not valid");
      return false;
    };
    return true;
  };


  const skipStep = useCallback(() => {
    Analytics.trackForRudder(RudderStackEvent.KYC_GST_SKIP);
    const redirectLink = window.sessionStorage.getItem("redirectTo");
    // const empanelmentLink = `${env.PARTNER_DASHBOARD_URL}/partner-nominee?flow=kyc`;
    // const appRedirectionLink = `${env.APP_LINK_URL}/profile`;

    if (!showSkipButton && redirectLink !== "null") {
      setRedirectLoading(true);
      setTimeout(() => {
        if(kycData?.flow === "PARTNER_GST"){
           if(checkIsWebView()){
            window.location.replace(redirectLink);
           } else {
             window.location.replace(redirectLink);
           }
        }
       else {
         window.location.replace(redirectLink);
       }
        setRedirectLoading(false);
      }, 1000);
    } else {
      skipPartnerStep();
    }
  }, [showSkipButton]);

  const onChangeHandler = (event) => {
    const {
      name,
      value
    } = event.target;

    const validationObj = {
      gstNo: () => value.length <= 15
    };

    if (validationObj.hasOwnProperty(name) && !validationObj[name]()) {
      return;
    }

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const onSubmit = async () => {
    try {
      const isValidated = validateFields();
      if (!isValidated) {
        return;
      }
      setIsLoading(true);
      const res = await uploadGstDetails(formData.gstNo);
      if (res.status == 200 || res.status == 201) {
        Analytics.trackForRudder(RudderStackEvent.KYC_GST_SUBMITTED);
        updateStatus();
      }
    } catch (error) {
      Analytics.trackForRudder(RudderStackEvent.KYC_SUBFLOW_FAILED);
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PartnerGst
      formData={formData}
      isLoading={isLoading}
      isRedirectLoading={isRedirectLoading}
      onChangeHandler={onChangeHandler}
      onSubmit={onSubmit}
      skipStep={skipStep}
      showSkipButton={showSkipButton}
    />
  );
};

PartnerGst.prototype = propTypes;

export default PartnerGstContainer;
