/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import React from "react";
import PanCardUpload from "../PartnerFlow/PanCardUpload";

import styled from "styled-components";

const PanCard = ({
  selectedSide,
  setSelectedSide
}) => {
  const tabContent = {
    "1": {
      id: "1",
      displayContent: "Upload document front page",
      component: <PanCardUpload
        key="1"
        subTitle={"Please upload front copy of your pan card."}
      />
    },
    "2": {
      id: "2",
      displayContent: "Upload document back page",
      component: <PanCardUpload
        key="2"
        subTitle={"Please upload back copy of your pan card."}
      />
    }
  };

  const content = Object.keys(tabContent).map((tabKey) => (
    <Tab
      className={tabKey === selectedSide ? "selected-tab" : ""}
      onClick={() => setSelectedSide(tabKey)}
    >
      <TabContent>{tabContent[tabKey].displayContent}</TabContent>
    </Tab>
  ));

  return (
    <>
      <TabWrapper>
        {content}
      </TabWrapper>
      {tabContent[selectedSide].component}
    </>
  );
};

const TabWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Tab = styled.div`
  width: 45%;
  opacity: 0.5;
  position: relative;
  border-bottom: 0;

  :hover {
    cursor: pointer;
  }

  &.selected-tab {
    opacity: 1;
    transition: all 200ms ease-in;

    &::after {
      content: "";
      border-bottom: 1px solid #6725F4;
      width: 195px;
      position: absolute;
      left: 20px;
    }
  }
`;

const TabContent = styled.p`
  font-size: 1.4rem;
  color: #000000;
  margin-bottom: 0.4rem;
  text-align: center;
  max-width: 190px;
  margin: 0 auto 0.4rem;
`;

export default PanCard;
