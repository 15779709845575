/**
Author - Brijesh Pandey
Git - https://github.com/Brijesh-Pandey
**/

import React, {
useState
} from "react";

import {
  handleApiError
} from "helpers";

import {
  skipStepInKycFlow
} from "api";

import Optional from "./Optional";

const OptionalContainer = ({
  updateStatus,
  handleSkipStep,
  skipStep
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSkip = async () => {
    try {
      setIsLoading(true);
      const res = await skipStepInKycFlow();
      if(res.status === 200) {
        updateStatus();
      }
    } catch(error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Optional
      handleSkip={handleSkip}
      isLoading={isLoading}
      handleSkipStep={handleSkipStep}
    />
  );
};

export default OptionalContainer;
