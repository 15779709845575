/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

const FlowType = {
  Manual: 'manual',
  Digilocker: 'digilocker'
};

export default FlowType;
