/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React, {
  useState
} from "react";

import styled, {
  css
} from "styled-components";

import {
  getTodaysDate,
  getYearsBack
} from "helpers";

import {
  ActionButton
} from "screens/commons";
const downArrow = "https://i.wlycdn.com/wealthy-home-page/partners-dropdown-arrow.svg";

const NomineeForm = ({
  checkIsMinor,
  formData,
  isLoading,
  isMinor,
  onChangeHandler,
  onSubmit,
  isDisabled,
  nomineeCountChangeHandler,
  nomineeCount,
  relationDropdownContent,
  relationDescription,
  setRelationDescription
}) => {
  let maxDate = getTodaysDate();
  let minDate = getYearsBack(18);
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <Container>
      <Title>Nominee Details</Title>
      <SubHeader>Add person as nominee for all investments</SubHeader>
      <InputsContainer>
        <InputContainer>
          <Label>Nominee Full Name</Label>
          <Input
            name="name"
            value={formData.name}
            onChange={onChangeHandler}
          // onCopy={(e) => e.preventDefault()}
          // onPaste={(e) => e.preventDefault()}
          // onCut={(e) => e.preventDefault()}
          />
        </InputContainer>

        <InputContainer>
          <Label>Nominee Date Of Birth</Label>
          <Input
            type="date"
            name="dob"
            max={maxDate}
            value={formData.dob}
            onChange={onChangeHandler}
            onBlur={checkIsMinor}
          // onCopy={(e) => e.preventDefault()}
          // onPaste={(e) => e.preventDefault()}
          // onCut={(e) => e.preventDefault()}
          />
        </InputContainer>

        {
          !isMinor && (
            <InputContainer>
              <Label>Nominee PAN Number</Label>
              <Input
                name="panNumber"
                value={formData.panNumber}
                onChange={onChangeHandler}
              // onCopy={(e) => e.preventDefault()}
              // onPaste={(e) => e.preventDefault()}
              // onCut={(e) => e.preventDefault()}
              />
            </InputContainer>
          )
        }

        <InputContainer>
          <Label>Your Relation With Nominee</Label>
          <DropdownContainer onClick={() => setShowDropdown(!showDropdown)}>
            <Select>{formData.relation ?? ""}</Select>
            <DropdownImage>
              <img
                alt="dropdown-arrow"
                src={downArrow}
              />
            </DropdownImage>
            {
              showDropdown && (
                <ul className="dropdown-menu">
                  {relationDropdownContent}
                </ul>
              )
            }
          </DropdownContainer>

          {
            formData.relation === "Others" ?
              <RelationOthersContainer>
                <Input
                  name="relation"
                  value={relationDescription}
                  placeholder="Please describe your relationship"
                  onChange={(event) => {
                    setRelationDescription(event.target.value);
                  }}
                // onCopy={(e) => e.preventDefault()}
                // onPaste={(e) => e.preventDefault()}
                // onCut={(e) => e.preventDefault()}
                />
              </RelationOthersContainer>
              : null
          }
        </InputContainer>

        {
          isMinor && (
            <>
              <InputContainer>
                <SubHeader>Note: Add guardian details for a minor nominee.</SubHeader>
              </InputContainer>
              <InputContainer>
                <Label>Guardian Name</Label>
                <Input
                  name="guardianName"
                  value={formData.guardianName}
                  onChange={onChangeHandler}
                // onCopy={(e) => e.preventDefault()}
                // onPaste={(e) => e.preventDefault()}
                // onCut={(e) => e.preventDefault()}
                />
              </InputContainer>

              <InputContainer>
                <Label>Guardian Date of birth</Label>
                <Input
                  type="date"
                  name="guardianDob"
                  max={minDate?.toString()}
                  value={formData.guardianDob}
                  onChange={onChangeHandler}
                // onCopy={(e) => e.preventDefault()}
                // onPaste={(e) => e.preventDefault()}
                // onCut={(e) => e.preventDefault()}
                />
              </InputContainer>

              <InputContainer>
                <Label>Guardian PAN Number</Label>
                <Input
                  name="guardianPanNumber"
                  value={formData.guardianPanNumber}
                  onChange={onChangeHandler}
                // onCopy={(e) => e.preventDefault()}
                // onPaste={(e) => e.preventDefault()}
                // onCut={(e) => e.preventDefault()}
                />
              </InputContainer>
            </>
          )
        }

        <ActionButton
          buttonText="Confirm"
          isDisabled={isDisabled}
          callback={onSubmit}
          isLoading={isLoading}
        />
        <ExtraSpace />

      </InputsContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  margin: 0 auto;
`;

const ExtraSpace = styled.div`
  height: 250px;
  margin: 10rem 0;
  opacity: 0;
`;

const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  letter-spacing: 0.1rem;
`;

const SubHeader = styled.p.attrs({
  className: 'description2'
})`
  margin: 1rem 0 0 0;
  padding: 0;
  color: #7E7E7E;
`;

const InputContainer = styled.div`
  margin-bottom: 3.3rem;
`;

const Label = styled.p.attrs({
  className: 'description2'
})`
  color: #7E7E7E;
`;

const Input = styled.input.attrs({
  className: 'form-input'
})`
  border: none;
  background: transparent;
  border-bottom: 1px solid #DDDDDD;
  outline: none;
  padding-bottom: 0.5rem;
  color: #000000;
  width: 100%;
  text-transform: uppercase;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button,
  ::-webkit-calendar-picker-indicator {
    opacity: 0;
    -webkit-appearance: none;
  }

  ${props => props.type === "date" && css`
    background: url(https://i.wlycdn.com/wealthy-kyc/kyc-calendar-icon.svg) no-repeat;
    background-position: right;
    width: 100%;
    min-height: 3.4rem;
    border-width: thin;
  `}
`;

const InputsContainer = styled.div`
  margin: 7rem 0 0 0;
`;

const RelationOthersContainer = styled.div`
  margin: 3rem 0 0 0;
`;

const Select = styled.div.attrs({
  className: 'button-title'
})`
  color: #000000;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
`;

const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  border: none;
  background: transparent;
  border-bottom: 1px solid #DDDDDD;
  outline: none;
  padding-bottom: 0.5rem;
  width: 100%;

  :hover {
    cursor: pointer;
  }

  .dropdown-menu {
    display: block;
    top: 6.1rem;
    width: 100%;
    border-radius: 1.2rem;
    position: absolute;
    height: 400px;
    overflow: scroll;
  }
  .dropdown-menu .dropdown-menu__item {
    font-size: 1.8rem;
    color: #6c6c6c;
    text-align: center;
    border-radius: 0;
    padding: 0.5rem 0;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const DropdownImage = styled.div`
  width: 2.4rem;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 1.8rem;
    >img {
      width: 100%;
      vertical-align: middle;
    }
  }
`;

export default NomineeForm;