/**
Author - Brijesh Pandey
Git - https://github.com/Brijesh-Pandey
**/

import React from 'react';
import Toggler from './Toggler';

const TogglerContainer = ({
  values,
  toggle,
  switchToggle
}) => (
  <Toggler
    values={values}
    toggle={toggle}
    switchToggle={switchToggle}
  />
);

export default TogglerContainer;
