/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React, {
  useState
} from "react";
import PropTypes from "prop-types";

import {
  Analytics,
} from "utils";
import {
  RudderStackEvent
} from "types";
import {
  partialResetFlow
} from "api";

import DigioFlow from "./DigioFlow";

const propTypes = {
  requestId: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  tokenId: PropTypes.string.isRequired,
  updateStatus: PropTypes.func.isRequired,
  kycData: PropTypes.object.isRequired,
  handleErrorState: PropTypes.func
};

const DigioFlowContainer = ({
  requestId,
  identifier,
  tokenId,
  updateStatus,
  kycData,
  handleErrorState
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [mountDigioComponent, setMountDigioComponent] = useState(false);

  const handleLoading = (error = false) => {
    if(error){
      Analytics.trackForRudder(RudderStackEvent.KYC_FAILED);
    }
    setIsError(error);
    setIsLoading(false);
    setMountDigioComponent(false);
  };

  const handleDigioInitialization = () => {
    setIsLoading(true);
    setMountDigioComponent(true);
  };

  const resetReason = "You have manually choose to reset.";
  const allowedFlowsForReset = ["MF_NRI", "PARTNER"];

  // flow can be MF_NRI or PARTNER
  const handleGoBack = async (flow) => {
    let reasons = {
      digilocker: resetReason
    };
    if(!allowedFlowsForReset.includes(flow)){
      return;
    };
    if(flow === "MF_NRI"){
      reasons = {
        ...reasons,
        ids_choice: resetReason
      };
    } else if(flow === "PARTNER"){
      reasons = {
        ...reasons,
        choice: resetReason
      };
    };
    try {
      const data = {
        kyc_id: kycData.id,
        rejections: {
          reasons: reasons
        }
      };
      await partialResetFlow(data);
      updateStatus();
    } catch (error) {
      setIsError(error);
    }
  };

  return (
    <DigioFlow
      handleDigioInitialization={handleDigioInitialization}
      handleGoBack={handleGoBack}
      isLoading={isLoading}
      isError={isError}
      handleLoading={handleLoading}
      mountDigioComponent={mountDigioComponent}
      requestId={requestId}
      identifier={identifier}
      tokenId={tokenId}
      updateStatus={updateStatus}
      setMountDigioComponent={setMountDigioComponent}
      kycData={kycData}
      handleErrorState={handleErrorState}
    />
  );
};

DigioFlowContainer.propTypes = propTypes;

export default DigioFlowContainer;
