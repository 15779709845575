/**
Author - Kaustubh Mishra
Git - git@github.com:KaustubhMishra25
**/


const ArnChoiceType = {
    Skip: 'skip',
    UploadArn: 'upload-arn'
};

export default ArnChoiceType;