/**
Author - Brijesh Pandey
Git - https://github.com/Brijesh-Pandey
**/

import React, {
  useState, useEffect
  } from "react";

  import SkipFlow from "./SkipFlow";

  const SkipFlowContainer = () => {
    const [timer, setTimer] = useState(5);

    useEffect(() => {
      if (timer === 0) {
        handleRedirection();
      }
      const intervalId = setInterval(() => {
        if(timer > 0) {
          setTimer(timer - 1);
        }
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }, [timer]);


    const proposalLink = window.sessionStorage.getItem("redirectTo");

    const handleRedirection = () => {
      if (proposalLink !== "null")
        window.location.replace(proposalLink);
    };

    return (
      <SkipFlow
        proposalLink={proposalLink}
        timer={timer}
      />
    );
  };

  export default SkipFlowContainer;
