/**
Author - Venkatesh Pullaganti
Git - https://github.com/venkatesh1146
**/

import React, {
  useState, useEffect
} from "react";
import {
  PropTypes
} from "prop-types";

import UploadDocumentContainer from "components/UploadDocument/UploadDocument.container";
import {
  getAddressProofMetaData,
  uploadAddressProof
} from "api";
import {
  handleApiError
} from "helpers";
import {
  LoaderContainer, Spinner
} from "../commons";


const propTypes = {
  updateStatus: PropTypes.func.isRequired,
};

const AddressProof = ({
  updateStatus
}) => {

  const [isUploading, setIsUploading] = useState(false);
  const [isFetchingMetaData, setIsFetchingMetaData] = useState(false);
  const [addressProofMetaData, setAddressProofMetaData] = useState(null);

  const handleSubmitFile = async (formData, docType) => {
    setIsUploading(true);
    try {
      const res = await uploadAddressProof(formData, docType);
      if (res.status === 200) {
        updateStatus();
      }
      if (!res.ok) {
        throw {
          response: {
            status: 400,
            data: {
              detail: {
                message: "File Format Not Supported"
              }
            }
          }
        };
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsUploading(false);
    }

  };

  const fetchMetaData = async () => {
    setIsFetchingMetaData(true);
    try {
      const res = await getAddressProofMetaData();
      setAddressProofMetaData(res.data);
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsFetchingMetaData(false);
    }
  };

  useEffect(() => {
    fetchMetaData();
  }, []);


  return isFetchingMetaData ? (
    <LoaderContainer>
      <Spinner
        color="blue"
        message="Updating your information, Please do not close the window"
      />
    </LoaderContainer>
  ) :
    <>
      {addressProofMetaData ? (
        <UploadDocumentContainer
          isLoading={isUploading}
          onSubmitDocument={handleSubmitFile}
          heading={addressProofMetaData?.pageTitle}
          subHeading={addressProofMetaData?.cardTitle}
          documentOptions={addressProofMetaData?.allowedAddressProofs}
        />
      ) :
        null}
    </>;
};

AddressProof.prototype = propTypes;
export default AddressProof;
