/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React, {
useState, useEffect
} from "react";

import styled from "styled-components";

import {
  getPersonalDetailSchema,
  updatePersonalDetails
} from "api";

import {
handleApiError
} from "helpers";

import {
Spinner
} from "../commons";

import PersonalDetails from "./PersonalDetails";

const PersonalDetailsContainer = ({
  updateStatus
}) => {
  const [formSchema, setFormSchema] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchSchema();
  }, []);

  const fetchSchema = async () => {
    try {
      setIsLoading(true);
      const response = await getPersonalDetailSchema();
      setFormSchema(response.data);
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFormSubmit = async (data) => {
    try {
      setIsLoading(true);
      const payload = {
        ...data,
        mothers_name: data.mothers_name?.toUpperCase(),
        fathers_name: data.fathers_name?.toUpperCase(),
        full_name: data.full_name?.toUpperCase()
      };

      const res = await updatePersonalDetails(payload);
      if (res.status == 200) {
        updateStatus();
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    };
  };

  return (
    isLoading ? (
      <LoaderContainer>
        <Spinner
          color="blue"
          message="Updating your information, Please do not close the window"
        />
      </LoaderContainer>
    ) : (
      <PersonalDetails
        formSchema={formSchema}
        handleFormSubmit={handleFormSubmit}
      />
    )
  );
};

const LoaderContainer = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default PersonalDetailsContainer;