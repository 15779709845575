/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import styled from "styled-components";

const AnimationText = styled.p`
  font-size: 1.4rem;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Animation = styled.div`
  height: 6.5rem;
  padding: 1rem;
  border-radius: 5px;
  margin-top: 2rem;
  width: 0;
  animation: uploading-progress 1s ease-out infinite;

  @keyframes uploading-progress {
    0% {
      background-color: rgba(255,255,255,1);
      width: 0;
    }
    100% {
      width: 100%;
      background-color: #5784eb;
    }
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  align-items: center;
`;

const DeleteWrapper = styled.div`
  width: 2rem;
  cursor: pointer;

  :hover {
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  }
`;

const DeleteIcon = styled.img`
  object-fit: "contain";
`;

const DetailWrapper = styled.div`
  margin-left: 1rem;
`;

const ExtraSpace = styled.div`
  height: 7rem;
  opacity: 0;
`;

const Text = styled.p`
  margin: 0;
  color: #193076;
  font-size: 1.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
`;

const ImageSize = styled.p`
  margin: 0;
  color: #193076;
  font-size: 1rem;
`;

const Wrapper = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  height: 100vh;
  margin: 0 auto;
  overflow: none;
`;

const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  letter-spacing: 0.1rem;
`;

const ImageCard = styled.div`
  height: 6.5rem;
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
  margin-top: 2rem;
  box-shadow: 0px 3px 8px #d0d0d06e;
  display: flex;
`;

const PreviewImage = styled.img`
  height: 100%;
  width: 5rem;
  object-fit: fill;
  border-radius: 5px;
`;

const SubHeader = styled.p.attrs({
  className: 'description2'
})`
  margin: 1rem 0 1rem 0;
  padding: 0;
  color: #7E7E7E;
`;

const ImagesContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  flex: 1;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    cursor: pointer;
  }
  flex-direction: column;
  span {
    color: #4A4A4A;
    font-weight: 500;
    font-size: 1.1rem;
    margin-top: .5rem;
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UploadImage = styled.img`
  height: 12.4rem;
`;

const UploadMessage = styled.p`
  margin-left: 2rem;
  text-align: center;
  margin-top: 1rem;
  font-size: 1.6rem;
`;

const DropZoneContainer = styled(ButtonContainer)`
  border: dashed 2px #5784eb;
  border-radius: 5px;
  cursor: pointer;
  color: #193076;
  height: 30rem;

  >span{
    color: #4A4A4A;
    font-weight: 500;
    font-size: .95rem;
    margin-top: .5rem;
  }
  i {
    margin-right: .5em;
  }
`;

export {
  AnimationText,
  Animation,
  ActionWrapper,
  DeleteWrapper,
  DeleteIcon,
  DetailWrapper,
  ExtraSpace,
  Text,
  ImageSize,
  Wrapper,
  Title,
  ImageCard,
  PreviewImage,
  SubHeader,
  ImagesContainer,
  ButtonContainer,
  Container,
  UploadImage,
  UploadMessage,
  DropZoneContainer
};
