
/*
  Author : Suyash Chandra
  email: suyash@wealthy.in
  GitHub: https://github.com/ChandraSuyash/
*/

"use strict";
import env from "env";

const Analytics = {
  initializeSegment: () => {
    const {rudderanalytics} = window;
    if (rudderanalytics && rudderanalytics.load) {
      rudderanalytics.load(env.RUDDERSTACK_WRITE_KEY, "https://wealthycowvwp.dataplane.rudderstack.com");
    }
  },

  identifyForRudder: (userId) => {
    const {rudderanalytics} = window;
    rudderanalytics && rudderanalytics.identify(`agent-${userId}`);
  },

  trackForRudder: (eventName) => {
    const {rudderanalytics} = window;

    rudderanalytics.track(eventName);
  },
};

export default Analytics;
