import React, {
 useEffect, useState
} from "react";
import PropTypes from "prop-types";

import {
  getChoiceSchema,
  selectFlowType,
} from "api";
import {
 handleApiError
} from "helpers";
import {
 Spinner, LoaderContainer
} from "../commons";
import Choice from "./Choice";

const propTypes = {
  updateStatus: PropTypes.func.isRequired,
  kycData: PropTypes.object,
};

const ChoiceContainer = ({updateStatus, kycData}) => {
  const [choicesData, setFormSchema] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    fetchSchema();
  }, []);

  const fetchSchema = async () => {
    try {
      setIsLoading(true);
      const response = await getChoiceSchema();
      setFormSchema(response?.data);
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSubmit = async (value) => {
    try {
      setIsLoading(true);
      const res = await selectFlowType({selection: value});
      if (res.status === 200) {
        updateStatus();
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <LoaderContainer>
      <Spinner
        color="blue"
        message="Updating your information, Please do not close the window"
      />
    </LoaderContainer>
  ) : (
    <>
      {choicesData ? (
        <Choice apiResponse={choicesData} handleSubmit={handleSubmit} />
      ) : (
        <p>No data found, please refresh your browser to get the list of options for KYC ids proof verification process.</p>
      )}
    </>
  );
};

ChoiceContainer.prototype = propTypes;

export default ChoiceContainer;
