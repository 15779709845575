/* eslint-disable no-throw-literal */

/**
Author - Pradip Dhakal
Git - https://github.com/pradipwealthy
**/

import React, {
 useState, useEffect
} from "react";
import PropTypes from "prop-types";

import UploadIds from "./UploadIds";
import {
 handleApiError, triggerToast
} from "helpers";
import {
 uploadIds
} from "api";

const propTypes = {
  updateStatus: PropTypes.func.isRequired,
};

const UplaodIdsContainer = ({updateStatus}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (uploadedFiles?.length > 2) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [uploadedFiles]);

  const handleDeleteUploadedFile = (file) => {
    const filteredList = uploadedFiles.filter(
      (eachFile) => eachFile.id !== file.id
    );
    setUploadedFiles(filteredList);
  };

  const handleSubmitFile = async (formData) => {
    setIsUploading(true);
    try {
      const res = await uploadIds(formData);
      if (res.status === 200) {
        updateStatus();
      }
      if (!res.ok) {
        throw {
          response: {
            status: 400,
            data: {
              detail: {
                message: "File Format Not Supported",
              },
            },
          },
        };
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsUploading(false);
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (uploadedFiles?.length === 3) {
      const formData = new FormData();
      uploadedFiles.map((f) => formData.append(f.id, f));
      await handleSubmitFile(formData);
    } else {
      triggerToast("Please add both front side and back side documents");
    }
  };

  const handleImageUpload = (files, side) => {
    let newFiles = [...uploadedFiles];

    newFiles = newFiles.filter((f) => f.id !== side);

    files.forEach((eachFile, index) => {
      eachFile.id = side;
      newFiles.push(eachFile);
    });

    let isLimitExceeded = false;

    if (!isLimitExceeded) {
      setUploadedFiles([...newFiles]);
    }
  };

  return (
    <UploadIds
      isLoading={isUploading}
      isDisabled={isDisabled}
      handleImageUpload={handleImageUpload}
      uploadedFiles={uploadedFiles}
      handleDeleteUploadedFile={handleDeleteUploadedFile}
      onSubmit={onSubmit}
    />
  );
};

UplaodIdsContainer.prototype = propTypes;

export default UplaodIdsContainer;
