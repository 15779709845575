/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

const RudderStackEventType = {
  KYC_CHOICE: "KYC_Choice",
  KYC_DIGILOCKER: "KYC_Digilocker",
  KYC_MANUAL: "KYC_Manual",
  KYC_COMPLETED: "KYC_Completed",
  KYC_SUBFLOW_SKIP: "KYC_SubflowSkip",
  KYC_ARN_DETECTED: "KYC_ARnDetected",
  KYC_ARN_DETECTION_FAILED: "KYC_ArnDetectionFailed",
  KYC_ARN_SKIP: "KYC_ArnSkip",
  KYC_ARN_UPLOAD: "KYC_ArnUpload",
  KYC_DOCUMENT_SIGN: "KYC_DocumentSign",
  KYC_DOCUMENT_SKIP: "KYC_DocumentSkip",
  KYC_FORM_SUBMIT: "KYC_FormSubmit",
  KYC_GST_SUBMITTED: "KYC_GstSubmitted",
  KYC_GST_SKIP: "KYC_GstSkip",
  KYC_BANK_DETAILS_SUBMITTED: "KYC_BankDetailsSubmitted",
  KYC_BANK_SKIP: "KYC_BankSkip",
  KYC_FAILED: "KYC_Failed",
  KYC_SUBFLOW_FAILED: "KYC_SubFlowFailed"
};

export default RudderStackEventType;
