import {
createTheme
} from "@mui/material/styles";

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#6725f4"
        }
    },
    typography: {
        fontFamily: "MavenPro",
        fontSize: 14*1.5 // 14*<rems>
    }
});


export default muiTheme;