/**
Author - Pradip Dhakal
Git - https://github.com/pradipwealthy
**/

import React from "react";
  
  import {
  PropTypes
  } from "prop-types";
  
  import UploadMultipleDocuments from "./UploadMultipleDocuments";

  const propTypes = {
    documents: PropTypes.array.isRequired,
    uploadedFiles: PropTypes.array.isRequired,
    handleDeleteUploadedFile: PropTypes.func.isRequired,
    commonInstructions: PropTypes.object.isRequired,
    actionButton: PropTypes.object.isRequired
  };
  
  
  const UploadDocumentContainer = ({
    documents,
    uploadedFiles,
    handleDeleteUploadedFile,
    commonInstructions,
    actionButton
  }) => (
      <UploadMultipleDocuments
        documents={documents}
        uploadedFiles={uploadedFiles}
        handleDeleteUploadedFile={handleDeleteUploadedFile}
        commonInstructions={commonInstructions}
        actionButton={actionButton}
      />
    );

  UploadDocumentContainer.propTypes = propTypes;
  
  export default UploadDocumentContainer;
  