/**
Author - Venkatesh😉
Git - https://github.com/venkatesh1146
**/

import React, {
  useState, useEffect
} from "react";
import styled from "styled-components";

import TradingPreferences from "./TradingPreferences"; 
import {
  getTradingPreferencesSchema,
  updateTradingPreferences
} from "api";

import {
  handleApiError
} from "helpers";

import {
  Spinner
} from "../commons";

export default function TradingPreferencesContainer({updateStatus}) {
  const [formSchema, setFormSchema] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    fetchSchema();
  }, []);

  const fetchSchema = async () => {
    try {
      setIsLoading(true);
      const response = await getTradingPreferencesSchema();
      setFormSchema(response.data);
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFormSubmit = async (data) => {
    try {
      setIsLoading(true);
      const res = await updateTradingPreferences(data);
      if (res.status == 200) {
        updateStatus();
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    };
  };
  
  return isLoading ? (
    <LoaderContainer>
      <Spinner
        color="blue"
        message="Updating your information, Please do not close the window"
      />
    </LoaderContainer>
  ) : (
      <TradingPreferences handleFormSubmit={handleFormSubmit} schema={formSchema} />
  );
}


const LoaderContainer = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
