/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React, {
useState, useEffect
} from "react";
import dateFormat from "dateformat";

import {
  updateNomineeDetails
} from "api";
import {
  getAge,
  handleApiError,
  dateFormatter,
  formValidator,
  WealthyValidations,
  triggerToast
} from "helpers";

import NomineeDetails from "./NomineeDetails";

const formFields = ["name", "dob", "relation", "panNumber"];
const minorFormFields = ["name", "dob", "relation", "guardianDob", "guardianName", "guardianPanNumber"];

const relationDropdownOptions = [
  {
    label: "Spouse",
    value: "Spouse"
  },
  {
    label: "Son",
    value: "Son"
  },
  {
    label: "Daughter",
    value: "Daughter"
  },
  {
    label: "Father",
    value: "Father"
  },
  {
    label: "Mother",
    value: "Mother"
  },
  {
    label: "Brother",
    value: "Brother"
  },
  {
    label: "Sister",
    value: "Sister"
  },
  {
    label: "Grand Son",
    value: "Grand Son"
  },
  {
    label: "Grand Daughter",
    value: "Grand Daughter"
  },
  {
    label: "Grand Father",
    value: "Grand Father"
  },
  {
    label: "Grand Mother",
    value: "Grand Mother"
  },
  {
    label: "Others",
    value: "Others"
  },
];

const NomineeDetailsContainer = ({
  kycData,
  updateStatus
}) => {
  const [formData, setFormData] = useState({
    name: kycData?.prefill?.name,
    dob: dateFormatter(kycData?.prefill?.dob),
    relation: kycData?.prefill?.relation,
    panNumber: kycData?.prefill?.panNumber ? kycData?.prefill?.panNumber : "",
    guardianName: kycData?.prefill?.guardianName,
    guardianDob: dateFormatter(kycData?.prefill?.guardianDob),
    guardianPanNumber: kycData?.prefill?.guardianPanNumber
  });
  const [nomineeCount, setNomineeCount] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isMinor, setIsMinor] = useState(kycData?.prefill?.isMinor);
  const [isDisabled, setIsDisabled] = useState(true);
  const [relationDescription, setRelationDescription] = useState("");

  useEffect(() => {
    if (isMinor) {
      setIsDisabled(formValidator(minorFormFields, formData));
    } else {
      setIsDisabled(formValidator(formFields, formData));
    }
  }, [formData, isMinor]);

  const nomineeCountChangeHandler = (event) => {
    setNomineeCount(nomineeCount + 1);
  };

  const checkIsMinor = () => {
    if (formData.dob !== "") {
      const age = getAge(formData.dob);
      if (age < 18) {
        setIsMinor(true);
      } else {
        setIsMinor(false);
      }
    }
  };

  const onChangeHandler = (event) => {
    const {
      name,
      value
    } = event.target;

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateFields = (data) => {
    const validatedName = WealthyValidations.validateFormField.name(data.name);
    const validatedPan = WealthyValidations.validateFormField.panGeneric(data.panNumber);

    if(!validatedName) {
      triggerToast("Name can only contain alphabets");
      return false;
    };

    if (isMinor) {
      const validatedGuardianPan = WealthyValidations.validateFormField.panGeneric(data.guardianPanNumber);
      if (!validatedGuardianPan) {
        triggerToast("Guardian Pan is invalid");
        return false;
      }
    };

    if (!isMinor && !validatedPan) {
      triggerToast("Pan is invalid");
        return false;
    };

    return true;
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      let dob = dateFormat(formData.dob, "isoDate").split("-").reverse().join("/");
      let guardianDob = "";

      let payload = {
        ...formData,
        dob
      };

      if(formData.relation === "Others"){
        payload = {
          ...payload,
          relation: relationDescription
        };
      }

      if (isMinor) {
        guardianDob =  dateFormat(formData.guardianDob, "isoDate").split("-").reverse().join("/");
        const panNumber = "";
        payload = {
          ...payload,
          panNumber,
          guardianDob
        };
      } else {
        delete payload["guardianName"];
        delete payload["guardianDob"];
        delete payload["guardianPanNumber"];
      }

      if (payload.panNumber?.length > 0) {
        let panNumber = payload.panNumber.toUpperCase();

        payload = {
          ...payload,
          panNumber
        };
      }

      if (payload.guardianPanNumber?.length > 0) {
        let guardianPanNumber = payload.guardianPanNumber.toUpperCase();

        payload = {
          ...payload,
          guardianPanNumber
        };
      }

      const isValidated = validateFields(payload);
      if (!isValidated) {
        return;
      }

      const res = await updateNomineeDetails(payload);
      if (res.status === 200) {
        updateStatus();
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const relationDropdownContent = relationDropdownOptions.map((option) => (
      <li
        className="dropdown-menu__item"
        key={option.label}
        onClick={(event)=> {
          const target = {
            name: "relation",
            value: option.value
          };
          onChangeHandler({...event, target});
        }}
      >
        {option.label}
      </li>
    ));

  return (
    <NomineeDetails
      checkIsMinor={checkIsMinor}
      formData={formData}
      isLoading={isLoading}
      isDisabled={isDisabled}
      isMinor={isMinor}
      onChangeHandler={onChangeHandler}
      onSubmit={onSubmit}
      nomineeCount={nomineeCount}
      setNomineeCount={setNomineeCount}
      nomineeCountChangeHandler={nomineeCountChangeHandler}
      relationDropdownContent={relationDropdownContent}
      relationDescription={relationDescription}
      setRelationDescription={setRelationDescription}
    />
  );

};

export default NomineeDetailsContainer;
