/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React from "react";

import styled from "styled-components";

const CustomInput = ({
  name,
  label,
  type,
  value,
  onChange,
  isCopyOperationDisabled,
  additionalStyles,
  onBlur,
  children
}) => (
  <InputContainer
    style={{...additionalStyles}}
  >
    <Label>{label}</Label>
    <Input
      name={name}
      type={type}
      value={value}
      onChange={onChange}
      onBlur={onBlur ? onBlur : () => { }}
      onCopy={(e) => isCopyOperationDisabled ? e.preventDefault() : {}}
      onPaste={(e) => isCopyOperationDisabled ? e.preventDefault() : {}}
      onCut={(e) => isCopyOperationDisabled ? e.preventDefault() : {}}
    />
    {children}
  </InputContainer>
);

const InputContainer = styled.div`
  margin-bottom: 3.3rem;
`;

const Label = styled.p.attrs({
  className: 'description2'
})`
  color: #7E7E7E;
`;

const Input = styled.input.attrs({
  className: 'form-input'
})`
  border: none;
  background: transparent;
  border-bottom: 1px solid #DDDDDD;
  outline: none;
  padding-bottom: 0.5rem;
  color: #000000;
  width: 100%;
  text-transform: uppercase;

  ::-webkit-outer-spin-button, ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance:textfield;
`;

export default CustomInput;
