import MetaForm from "@manojadams/metaforms";
import React from 'react';
import {
  ThemeProvider
} from "@mui/material/styles";
import muiTheme from "styles/theme";
import styled from 'styled-components';

import {
  ActionButton
} from "../commons";

export default function Address({schema, onSubmit, heading = 'Address', subHeading = 'Please fill your foreign address', isLoading = false}) {
  return (
    <ThemeProvider theme={muiTheme}>
      <FormWrapper>
        <Title>{heading}</Title>
        <SubHeader>{subHeading}</SubHeader>
        <MetaForm
          schema={schema}
          onSubmit={onSubmit}
          buttons={{submit: <ActionButton additionalStyles={{padding: "0", margin: "0", color: "white", height: "40px"}} isLoading={isLoading} buttonText="Submit" />}}

        />
      </FormWrapper>
    </ThemeProvider>
  );
}


const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  color: #000000;
  letter-spacing: 0.1rem;
`;

const SubHeader = styled.p.attrs({
  className: 'description2'
})`
  margin: 1rem 0 0 0;
  padding: 0;
  color: #7E7E7E;
`;

const FormWrapper = styled.div`
  @media screen and (max-width: 768px) {
    padding:1rem 1.5rem;
  }
  .input-container {
    margin-bottom: 1.5rem;
  }

  .input-container label {
    font-size: 1.5rem;
    font-weight: 400;
    color: #7E7E7E;
  }

  .input-container label+div {
    font-size: 1.6rem;
    font-weight: 400;
    color: #000000;
  }

  .input-container input {
    border: none;
    background: transparent;
    border-bottom: 1px solid #DDDDDD;
    outline: none;
    padding-bottom: 0.5rem;
    color: #000000;
    font-size: 1.6rem;
    font-family: MavenPro;
    font-weight: 500;
    width: 100%;
  }

  .action-btn {
    padding: 1.5rem 0;
    background: #6725F4;
    border-radius: 52px;
    width: 100%;
    margin-top: 3rem;
    color: #ffffff;
    font-size: 1.6rem;
    font-family: MavenPro;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: scale 300ms;

    :hover {
      background-color: #6725F4;
      scale: 1.02;
    }
  }
`;