/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React from "react";

import styled from "styled-components";

import {
  ActionButton
} from "screens/commons";
import {
Nominee
} from "./content";

const Optional = ({
  isLoading,
  handleSkip,
  handleSkipStep
}) => {
  const activeContent = Nominee;

  return (
    <OptionalWrapper>
      <Container>
        <Title>{activeContent.title}</Title>
        <SubHeader>{activeContent.subtext}</SubHeader>
        <Description>{activeContent.description}</Description>
        <ButtonWrapper>
          <ActionButton
            buttonText="Continue"
            callback={() => handleSkipStep(1)}
            isLoading={isLoading}
          />

          <SkipButton
            onClick={handleSkip}
          >
            I don't wish to Nominate
          </SkipButton>
        </ButtonWrapper>
      </Container>
    </OptionalWrapper>
  );
};

const OptionalWrapper = styled.div`
  height: 85vh;
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;

  @media (max-width: 768px) {
    background:#fff;
    height: 100vh;
  }
`;

const Container = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  margin: 0 auto;
`;

const ButtonWrapper = styled.div`
  margin: 8rem 0 0 0;
`;

const Title = styled.h1.attrs({
  className:'primary-title'
})`
  margin: 0;
  padding: 0;
  letter-spacing: 0.1rem;
`;

const SubHeader = styled.p.attrs({
  className: 'description2'
})`
  margin: 5rem 0 2rem 0;
  padding: 0;
  color: #7E7E7E;
`;

const SkipButton = styled.button.attrs({
  className: 'button-title'
})`
  background: transparent;
  color: #6725F4;
  border-radius: 52px !important;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background: transparent;
  }
`;

const Description = styled(SubHeader)``;

export default Optional;
