/**
Author - Venkatesh Pullaganti
Git - https://github.com/venkatesh1146
**/

import React, {
  useEffect,
  useState
} from 'react';
import PropTypes from "prop-types";

import {
  getAddressSchema,
  saveAddressData
} from "api";
import {
  handleApiError
} from "helpers";

import {
  Spinner, LoaderContainer
} from "../commons";


import Address from "./Address";

const propTypes = {
  updateStatus: PropTypes.func.isRequired,
  kycData: PropTypes.object
};

const AddressContainer = ({updateStatus, kycData}) => {
  const [addressData, setFormSchema] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdatingAddress, setIsUpdatingAddress] = useState(false);
  useEffect(() => {
    fetchSchema();
  }, []);

  const fetchSchema = async () => {
    try {
      setIsLoading(true);
      const response = await getAddressSchema();
      setFormSchema(response?.data);
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (formData) => {
    const addressParts = [formData.line1, formData.line2, formData.line3, formData?.locality_or_post_office, formData?.district_or_city, formData?.state, formData?.pincode, formData?.country].filter(Boolean);
    const address = addressParts.join(', ');
    formData.address = `${address}`;
    setIsUpdatingAddress(true);
    saveAddressData(formData).then(() => {
      updateStatus();
    }).catch(handleApiError).finally(() => {
      setIsUpdatingAddress(false);
    });
  };

  return (
    isLoading ? (
      <LoaderContainer>
        <Spinner
          color="blue"
          message="Updating your information, Please do not close the window"
        />
      </LoaderContainer>
    ) :
      <>
        {addressData ? <Address isLoading={isUpdatingAddress} heading={addressData.pageTitle} subHeading={addressData.cardTitle} schema={addressData?.schema} onSubmit={handleSubmit} /> : <p>Details not found</p>}
      </>
  );
};
AddressContainer.prototype = propTypes;

export default AddressContainer;
