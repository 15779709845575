/**
Author - Brijesh Pandey
Git - https://github.com/Brijesh-Pandey
**/

import React, {
useState, useRef
} from "react";

import PropTypes from "prop-types";

import Dropdown from "./Dropdown";

import {
  useOnClickOutside
} from "hooks";

const propTypes = {
  options: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
};

const DropdownContainer = ({
  title,
  options
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef();

  useOnClickOutside(dropdownRef, () => setShowDropdown(false));

  return (
    <Dropdown
      dropdownRef={dropdownRef}
      options={options}
      setShowDropdown={setShowDropdown}
      showDropdown={showDropdown}
      title={title}
    />
  );
};

DropdownContainer.propTypes = propTypes;

export default DropdownContainer;
