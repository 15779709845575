/**
Author - Brijesh Pandey
Git - https://github.com/Brijesh-Pandey
**/

import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import parse from 'html-react-parser';

import {
  ActionButton,
  Dropdown
} from "screens/commons";

import {
  AnimationText,
  Animation,
  AttentionContainer,
  ActionWrapper,
  DeleteWrapper,
  DeleteIcon,
  DetailWrapper,
  ExtraSpace,
  Text,
  ImageSize,
  Wrapper,
  Title,
  ImageCard,
  PreviewImage,
  SubHeader,
  ImagesContainer,
  Container,
  UploadImage,
  UploadMessage,
  DropZoneContainer,
  EachOption,
  PasswordInput
} from "./UploadDocument.styles";

const propTypes = {
  handleImageUpload: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array.isRequired,
  showAnimation: PropTypes.bool.isRequired,
  handleDeleteUploadedFile: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

const UploadDocument = ({
  declarationsList,
  isLoading,
  isDisabled,
  handleImageUpload,
  uploadedFiles,
  showAnimation,
  handleDeleteUploadedFile,
  onSubmit,
  selectedOption,
  setSelectedOption,
  setPassword,
  password
}) => {
  const fileUploadImage = "https://i.wlycdn.com/wealthy-home-page/upload-cloud-icon.png";
  const deleteImage = "https://i.wlycdn.com/wealthy-home-page/partner-close-button.svg";

  const dropdownOptions = declarationsList.allowedIncomeDeclarations?.map((eachDeclaration) => (
      <>
        <EachOption
          onClick={() => setSelectedOption(eachDeclaration)}
        >
          {parse(eachDeclaration.render)}
        </EachOption>
      </>
    ));

  return (
    <Wrapper>
      <Title>Document Upload</Title>
      <SubHeader>Please choose a document type and upload it.</SubHeader>
      <ExtraSpace />

      <Dropdown
        options={dropdownOptions}
        title={selectedOption.render ? parse(selectedOption?.render) : "Choose an option"}
      />

      <DropZoneContainer>
        <Dropzone
          onDrop={handleImageUpload}
          multiple={false}
          accept={{
            "image/png": [".png"],
            "image/jpeg": [".jpg", ".jpeg"],
            "application/pdf": [".pdf"]
          }}
        >
          {({getRootProps, getInputProps}) => (
            <section>
              <div {...getRootProps()}>
                <Container>
                  <UploadImage src={fileUploadImage} />
                </Container>
                <input {...getInputProps()} />
                <UploadMessage>Drag 'n' drop the chosen document here, or click to select files</UploadMessage>
              </div>
            </section>
          )}
        </Dropzone>
      </DropZoneContainer>

      <AttentionContainer><span>Attention:</span> Please provide password in the below input if it is a password-protected document.</AttentionContainer>


      <PasswordInput
        placeholder="Type your password here..."
        type="password"
        value={password}
        onChange={(event) => setPassword(event.target.value)}
      />

      <ImagesContainer>
        {
          uploadedFiles.map((eachFile) => (
            showAnimation ? (
              <Animation>
                <AnimationText>Uploading...</AnimationText>
              </Animation>
            ) : (
              <>
                <ImageCard>
                  <PreviewImage src={URL.createObjectURL(eachFile)} />
                  <DetailWrapper>
                    <Text>{eachFile.name}</Text>
                    <ImageSize>{eachFile.size * 0.001}Kb</ImageSize>
                  </DetailWrapper>
                  <ActionWrapper>
                    <DeleteWrapper onClick={() => handleDeleteUploadedFile(eachFile)}>
                      <DeleteIcon
                        alt="delete image"
                        src={deleteImage}
                      />
                    </DeleteWrapper>
                  </ActionWrapper>
                </ImageCard>
              </>
            )
          ))
        }
      </ImagesContainer>
      <ActionButton
        buttonText="Upload & Continue"
        callback={onSubmit}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
    </Wrapper>
  );
};

UploadDocument.propTypes = propTypes;

export default UploadDocument;
