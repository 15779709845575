/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React from "react";
import styled from "styled-components";

import {
  ActionButton
} from "screens/commons";

const successScreenImage = "https://i.wlycdn.com/wealthy-home-page/application-success-screen-kyc.svg";

const defaultData = {
  title: "Application submitted successfully",
  content: "It usually takes up to 2 working days to review and accept your application. We will inform you once your application is accepted for you to continue to investing. You can click on 'Activate F&O' to enable the trading in F&O or click on 'continue' to explore rest of the app 🔍",
  ctaText: "Submit"
};

const SuccessScreen = ({
  kycData,
  handleSubFlow,
  isSubflowPresent,
  proposalLink,
  isLoading,
  timer
}) => {
  const isSuccessContentPresent = !!kycData.successContent?.title;
  const title = isSuccessContentPresent ? kycData.successContent?.title : defaultData.title;
  const content = isSuccessContentPresent ? kycData.successContent?.msg : defaultData.content;
  const ctaText = isSuccessContentPresent ? kycData.successContent?.cta : defaultData.ctaText;

  return (
    <SuccessScreenWrapper>
      <Container>
        <DataContainer>
          <ImageContainer>
            <img alt="connect-with-wealth-partner" src={successScreenImage} />
          </ImageContainer>
          <Title>{title}</Title>
          <SubHeader>
            {content}
          </SubHeader>
          {
            !isSubflowPresent ? (
              <SuccessText>
                {
                  proposalLink?.length && proposalLink !== "null" ? (
                    `Redirecting you in ${timer} seconds`
                  ) : (
                    "Now you can close the browser to go back to the app."
                  )
                }
              </SuccessText>
            ) : (null)
          }
          {
            isSubflowPresent ? (
              <>
                <ActionButton
                  buttonText={ctaText}
                  callback={handleSubFlow}
                  isLoading={isLoading}
                />
              </>
            ) : (
              null
            )
          }
        </DataContainer>
      </Container>
    </SuccessScreenWrapper>
  );
};

const SuccessScreenWrapper = styled.div`
  height: 100vh;
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;

  @media (max-width: 768px) {
    background:#fff;
  }
`;

const Container = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  margin: 0 auto;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ImageContainer = styled.div``;

const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  color: #000000;
  margin-top: 3.6rem;
  line-height: 1.4;
  letter-spacing: 0.1rem;
`;

const SubHeader = styled.p.attrs({
  className: 'description2'
})`
  margin: 1rem 0 0 0;
  padding: 0;
  text-align: center;
  color: #7E7E7E;
  margin-top: 1.6rem;
  max-width: 90%;
  :not(:first-child) {
    margin-top: 3rem;
  }
`;

const SuccessText = styled(SubHeader)``;

export default SuccessScreen;
