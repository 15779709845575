/**
Author - Venkatesh Pullaganti
Git - https://github.com/venkatesh1146
**/

import React, {
  useState,
  useEffect
} from "react";
import {
  PropTypes
} from "prop-types";
import {
  triggerToast
} from "helpers";

import UploadTwoSidedDocument from "./UploadTwoSidedDoc";


const propTypes = {
  onSubmitDocument: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  documentOptions: PropTypes.array
};

const UploadTwoSidedDocumentContainer = ({
  onSubmitDocument,
  isLoading,
  heading,
  subHeading,
  documentOptions,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showAnimation, setShowAnimation] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [password, setPassword] = useState({frontSide: null, backSide: null});

  useEffect(() => {
    if (documentOptions.length) {
      setSelectedOption(documentOptions[0]);
    }
  }, []);

  useEffect(() => {
    if (uploadedFiles.length > 1) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [uploadedFiles]);

  const startAnimation = () => {
    setShowAnimation(true);
    setTimeout(() => {
      setShowAnimation(false);
    }, 1200);
  };

  const handleDeleteUploadedFile = (file) => {
    const filteredList = uploadedFiles.filter((eachFile) => eachFile.id !== file.id);
    setUploadedFiles(filteredList);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (uploadedFiles.length === 2) {
      const formData = new FormData();
      uploadedFiles.map(f => formData.append(f.id, f));
      formData.append("front_doc_pass", password.frontSide);
      formData.append("back_doc_pass", password.backSide);
      onSubmitDocument(formData);
    } else {
      triggerToast("Please add both front side and back side documents");
    }
  };

  const handleImageUpload = (files, side) => {
    let newFiles = [...uploadedFiles];

    newFiles = newFiles.filter(f => f.id !== side);

    files.forEach((eachFile, index) => {
      eachFile.id = side;
      newFiles.push(eachFile);
    });

    let isLimitExceeded = false;

    if (!isLimitExceeded) {
      setUploadedFiles([...newFiles]);
      startAnimation();
    }
  };

  return (
    <UploadTwoSidedDocument
      handleImageUpload={handleImageUpload}
      handleDeleteUploadedFile={handleDeleteUploadedFile}
      isDisabled={isDisabled}
      isLoading={isLoading}
      uploadedFiles={uploadedFiles}
      showAnimation={showAnimation}
      onSubmit={onSubmit}
      heading={heading}
      subHeading={subHeading}
      documentOptions={documentOptions}
      allowPasswordProtected
      setSelectedOption={setSelectedOption}
      selectedOption={selectedOption}
      password={password}
      setPassword={setPassword}
    />
  );
};

UploadTwoSidedDocumentContainer.prototype = propTypes;

export default UploadTwoSidedDocumentContainer;
