/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React from "react";

import styled from "styled-components";

import {
ActionButton
} from "screens/commons";

const FaceMatchContainer = ({
  updateStatus
}) => {
  const handleNextStep = () => {
    setTimeout(() => {
      updateStatus();
    }, 1000);
  };

  return (
    <Container>
      <NextStep>Next step is Face Match</NextStep>
      <Subtitle>Thank you for your patience, please click on the below button to move forward</Subtitle>
      <ActionButton
        buttonText="Continue"
        callback={handleNextStep}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  margin: 0 auto;
`;

const NextStep = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  color: #000000;
  margin-bottom: 4rem;
  letter-spacing: 0.1rem;
`;

const Subtitle = styled.p.attrs({
  className: 'description2'
})`
  margin: 1rem 0 10rem 0;
  padding: 0;
  color: #7E7E7E;
`;

export default FaceMatchContainer;