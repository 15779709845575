/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React from "react";

import styled from "styled-components";

import Spinner from "../Spinner";

const ActionButton = ({
  buttonText,
  callback,
  additionalStyles,
  isLoading,
  isDisabled,
  className = ""
}) => (
  <ButtonContainer className={className} disabled={isDisabled} style={{...additionalStyles}} onClick={callback}>
    {
      isLoading ? (
        <Spinner className="spinner-container" />
      ) : (buttonText)
    }
  </ButtonContainer>
);

const ButtonContainer = styled.button.attrs({
  className: 'button-title'
})`
  padding: 2rem 0;
  background: #6725F4;
  border-radius: 52px !important;
  width: 100%;
  margin-top: 3rem;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  :disabled {
    background-color: rgb(236, 236, 236);
    color: rgb(161, 161, 161);
    pointer-events: none;
  }

  .spinner-container {
    margin: 0rem auto;
  }
`;

export default ActionButton;
