/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  ActionButton,
  Spinner
} from "screens/commons";

const propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

const CheckAcess = ({
  handleFormSubmit,
  formData,
  setFormData,
  isLoading
}) => (
  isLoading ? (
    <LoaderContainer>
      <Spinner
        color="blue"
        message="Updating your information, Please do not close the window"
      />
    </LoaderContainer>
  ) : (
    <Container>
      <Wrapper>
        <Title>Check Acess</Title>
        <SubHeader>Do you have access to email or phone fetched below. </SubHeader>
        <InputsContainer>
          <InputContainer>
            <Input type="checkbox"
              id="email"
              onChange={(event) => setFormData({...formData, isEmailChecked: !formData.isEmailChecked})}
            />
            <Label htmlFor="email">{formData.email}</Label>
          </InputContainer>
          <InputContainer>
            <Input type="checkbox"
              id="phone"
              onChange={(event) => setFormData({...formData, isMobileChecked: !formData.isMobileChecked})}
            />
            <Label htmlFor="phone">{formData.mobile}</Label>
          </InputContainer>
          <DisclaimerContainer>
            An OTP will be sent for verification purpose
          </DisclaimerContainer>
          <ActionButton
            buttonText="Confirm"
            callback={handleFormSubmit}
          />
        </InputsContainer>
      </Wrapper>
    </Container>
  )
);

const Container = styled.div`
  height: 100vh;
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;

  @media (max-width: 768px) {
    background:#fff;
  }
`;

const LoaderContainer = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  margin: 0 auto;
`;

const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  color: #000000;
  letter-spacing: 0.1rem;
`;

const SubHeader = styled.p.attrs({
  className: 'description2'
})`
  margin: 1rem 0 0 0;
  padding: 0;
  color: #7E7E7E;
`;

const DisclaimerContainer = styled.div`
  color: #7E7E7E;
  font-size: 1.4rem;
  margin-top: 5rem;
  border-radius: 4px;
`;

const InputContainer = styled.div`
  margin-bottom: 3.3rem;
  display: flex;
  flex-direction: row;
`;

const Label = styled.label`
  font-size: 1.5rem;
  font-weight: 400;
  color: #000000;
  cursor: pointer;
`;

const Input = styled.input.attrs(props => {
  return {
    type: props.type || "number",
    className: 'form-input'
  };
})`
  border: none;
  background: transparent;
  border-bottom: 1px solid #DDDDDD;
  outline: none;
  padding-bottom: 0.5rem;
  color: #000000;
  width: 100%;
  margin-right: 1rem;
  cursor: pointer;
  accent-color: #6725F4
`;

const InputsContainer = styled.div`
  margin: 7rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

CheckAcess.propTypes = propTypes;

export default CheckAcess;
