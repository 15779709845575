/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

const formValidator = (formFields = [], formData) => {
  let isFormValidated = false;
  for (const input of formFields) {
    if (!formData[input]?.length > 0) {
      isFormValidated = true;
    }
  }
  return isFormValidated;
};

export default formValidator;
