import Cookies from 'js-cookie';
import {
 SOURCE_KEY
} from '../constants/cookie';

const CLIENT_FLUTTER_APP_HANDLER = 'client_app_web_communication_handler';

export const sendEventsToClientApp = (event: string) => {
  try {
    window?.flutter_inappwebview?.callHandler(CLIENT_FLUTTER_APP_HANDLER, event);
  } catch (error) {
    console.error(error);
  }
};

export const isInAppWebview = () => {
  const source =
    Cookies.get(SOURCE_KEY) ?? '';
  return source?.includes('android') || source?.includes('ios');
};
