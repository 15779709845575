import {
  CLIENT_APP_APP_STORE_LINK,
  CLIENT_APP_PLAY_STORE_LINK
} from 'constants/Urls';
import IMAGES from 'constants/Images';
import React from 'react';
import styled from 'styled-components';
import {
  OpsEvaluationStyles
} from './styles';

const OpsEvaluation = () => (
  <>
    <OpsEvaluationStyles />
    <Wrapper>
      <Image className='wealthy-logo' src={IMAGES.wealthyLogo} />
      <HeadSection className='head-section'>
        <Image className='profile-search-icon' src={IMAGES.profileCardSearch} height={150} width={150} />
        <Header className='title-txt'>Request Received</Header>
        <Text className='sub-text'>We have received your request. Our team is currently verifying your information. If everything is confirmed, you will soon receive the eSign form in your registered Email</Text>
        <TimeDuration>
          <Image style={{marginRight: '8px'}} src={IMAGES.infoIcon} height={16} width={16} />
          It takes around 1 day for KYC to Authorize
        </TimeDuration>
      </HeadSection>
      <Divider />
      <LinksSection className='links-section'>
        <DownloadAppTxt className='download-app-txt'>
          Download the wealthy App <br /> enjoy efficient Trading experience
        </DownloadAppTxt>
        <DownloadIcons>
          <a href={CLIENT_APP_PLAY_STORE_LINK}>
            <Image className='store-icon' src={IMAGES.playStore} />
          </a>
          <a href={CLIENT_APP_APP_STORE_LINK}>
            <Image className='store-icon' src={IMAGES.appStore} />
          </a>
        </DownloadIcons>
      </LinksSection>
    </Wrapper>
  </>
);

export default OpsEvaluation;

const HeadSection = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LinksSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const DownloadIcons = styled.div`
  .store-icon{
    margin-right:1rem;
    width:120px;
    height:120px;
    @media screen and (min-width:720px) {
      width:160px;
    height:160px;
    }
  }

`;

const Image = styled.img``;

const Divider = styled.div`
  border:1px dashed white;
  width:100%;
  margin:44px 0;
  @media screen and (min-width:725px){
    width:2rem;
    height: 85%;
    border:none;
    border-right:1px solid #A69EBC;
    margin:6rem 4rem;
    
  } 
`;
const DownloadAppTxt = styled.p`
  color:  #FFF;
  text-align: center;
  font-family: Maven Pro;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 38.5px; 
  text-transform: capitalize;
`;
export const Wrapper = styled.div`
  background: #1E1730;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height:100vh;
  position: relative;
  @media screen and (max-width:720px) {
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    .head-section, .links-section{
      width:100%;
    }
    .wealthy-logo{
      display: none;
    }
  }
  @media screen and (min-width:721px){
    .head-section, .links-section{
      width:40%;
    }
    .links-section{
      padding-top: 2rem;
    }
    .head-section{
      padding-bottom: 4rem;
    }
    .wealthy-logo{
      position: absolute;
      top:4rem;
      left:4rem;
    }
    .download-app-txt {
      font-size:2.8rem;
      text-align: center;
    }

    .store-icon{
      height:180px;
      width:191px;
    }
    .profile-search-icon{
      width:250px;
      height:250px;
    }
    .title-txt {
      font-size:2.8rem;
      margin-top: -2rem;
      margin-bottom: 3rem;
    }
    .sub-text{
      font-size:2rem;
    }
    
  }
`;

const Header = styled.h1`
  color:  #FFF;
  text-align: center;
  font-family: Marcellus;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Text = styled.p`
  color:  #FFF;
  text-align: center;
  font-family: Maven Pro;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 

`;

const TimeDuration = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  color:  #C4BAFF;
  font-family: Maven Pro;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
`;