export const FLOW_DISPLAY_NAME = {
    "MF": "Mutual Funds",
    "BANK": "Bank -",   //Independent w-flow.
    "MF_SHORT": "Mutual Funds",
    "MF_NRI": "Mutual Funds - NRI",
    "MF_NRI_RE_KYC": "Mutual Funds - NRI, RE -",
    "BROKING": "Trading - Equity",
    "BROKING_DDPI": "Trading - DDPI",
    "BROKING_FNO": "Trading - F & O",
    "PARTNER": "PARTNER",
    "PARTNER_BANK": "PARTNER-BANK",
    "PARTNER_ARN": "PARTNER-ARN",
    "PARTNER_GST": "PARTNER-GST",
};
