/**
Author - Brijesh Pandey
Git - https://github.com/Brijesh-Pandey
**/

import UploadTwoSidedDocument from "components/UploadTwoSidedDoc";
import React from "react";

import styled from "styled-components";

const Passport = ({
  handleSubmitFile,
  isUploading,
}) => (
  <TabWrapper>
    <UploadTwoSidedDocument
      onSubmitDocument={handleSubmitFile}
      isLoading={isUploading}
      heading={"Upload Passport"}
      subHeading={"Please upload both sides of the passport"}
      documentOptions={[]}
    />
  </TabWrapper>
);

const TabWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default Passport;
