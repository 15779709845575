/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  ActionButton
} from "screens/commons";
import {
  KycScreenMapping,
  KycFlowMapping
} from "types";
import IMAGES from "constants/Images";

const propTypes = {
  kycData: PropTypes.object.isRequired,
  handleDigioInitialization: PropTypes.func.isRequired,
  handleGoBack: PropTypes.func.isRequired
};

const PartnerKyc = ({
  kycData,
  handleDigioInitialization,
  handleGoBack
}) => {
  const digiLocker = "https://i.wlycdn.com/wealthy-kyc/digi-locker.svg";
  const selfieUpload = "https://i.wlycdn.com/wealthy-kyc/selfie-upload.svg";
  const panUpload = "https://i.wlycdn.com/wealthy-kyc/pan-upload.svg";

  const {stage,flow} = kycData;

  let title = "DigiLocker KYC";
  let subTitle = "Redirecting to Digilocker";
  let description = "";
  let imgPath = digiLocker;
  let goBackButtonText = "Process Manually";

  switch (stage) {
    case KycScreenMapping.DIGILOCKER:
      title = "DigiLocker KYC";
      subTitle = "Redirecting to Digilocker";
      description = "";
      imgPath = digiLocker;
      break;
    case KycScreenMapping.UPLOAD_PAN:
      title = "Upload Pan";
      subTitle = "Upload PAN Card";
      description = "Upload the front of your PAN Card for verification";
      imgPath = panUpload;
      break;
    case KycScreenMapping.SELFIE:
      title = "Upload Selfie";
      subTitle = "Upload Selfie";
      description = "Upload your selfie for verification";
      imgPath = selfieUpload;
      break;
    default:
      title = "DigiLocker KYC";
      subTitle = "Redirecting to Digilocker";
      description = "";
      imgPath = digiLocker;
      break;
  }

  return (
    <Container>
      <KycWrapper>
        <Title>{title}</Title>
        <Wrapper>
          {
            stage === KycScreenMapping.DIGILOCKER ?
              (
                <DigiLockerImage>
                  <img
                    alt={title}
                    className="kyc-image"
                    src={imgPath}
                  />
                </DigiLockerImage>
              ) : (
                <KycImage>
                  <img
                    alt={title}
                    className="kyc-image"
                    src={imgPath}
                  />
                </KycImage>
              )}

          <Subtitle>{subTitle}</Subtitle>
          {
            stage === KycScreenMapping.DIGILOCKER ?
              (
                <NoteContainer>
                  <NoteTitle>
                    Important Pointers:
                  </NoteTitle>
                  <NoteDescription>
                    Keep your Aadhar number and mobile
                    number linked with Aadhar handy.
                  </NoteDescription>
                  <NoteDescription>
                    You will receive OTP on this number.
                  </NoteDescription>
                  <NoteDescription>
                    If you haven't set up your DigiLocker PIN,
                    or if you've forgotten it, click on "Forgot
                    security PIN" on the DigiLocker KYC screen.
                  </NoteDescription>
                </NoteContainer>
              ) : (
                <Description>{description}</Description>
              )}
        </Wrapper>
        <ActionButton
          buttonText="Continue"
          callback={handleDigioInitialization}
        />
        {(stage === KycScreenMapping.DIGILOCKER && flow === KycFlowMapping.PARTNER) && (
          <>
            <ActionButton
              buttonText={goBackButtonText}
              callback={() => handleGoBack(flow)}
              additionalStyles={{
                background: 'gray'
              }}
            />
            <span>Incase of failure or face any issues with digilocker there is always options for upload your id proofs manually. 
              &nbsp;<img src={IMAGES.infoIconThemeColor} alt="info-icon" title="Choose Manual option after clicking this button" />
            </span>
          </>
        )}
      </KycWrapper>
    </Container>
  );
};

const Container = styled.div`
  padding: 2.4rem 0;
  margin: 0 auto;
  height: 100vh;
  width: 80%;
`;

const KycWrapper = styled.div`
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -5rem;
  align-items: center;
`;

const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  color: #000000;
  letter-spacing: 0.1rem;
  align-self: start;
  margin-bottom: 15rem;

`;

const Subtitle = styled.p.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  color: #000000;
  letter-spacing: 0.1rem;
`;

const Description = styled.span.attrs({
  className: 'description2'
})`
  margin: 1rem 0 0 0;
  padding: 0;
  color: #7E7E7E;
  width: 80%;
  text-align: center;
`;

const NoteContainer = styled.div`
  background-color: #F9F9F9;
  border-raidus: 0.375rem;
  padding: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 4rem;
`;

const NoteTitle = styled.div.attrs({
  className: 'description2'
})`
  color: #7E7E7E;
  font-size: 1.4rem;
  font-weight: 600 !important;
`;

const NoteDescription = styled.div.attrs({
  className: 'description2'
})`
  color: #1C1C1C;
  margin-top: 1rem;
`;

const KycImage = styled.div`
  align-self: center;
  margin-bottom: 4rem;
  background-color: #ECF0FC;
  border-radius: 0.75rem;
  height: 17.5rem;
  width: 17.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3.125rem 0rem;

  .kyc-image {
    height: 100%;
    width: 90%;
  }
`;

const DigiLockerImage = styled.div`
  align-self: center;
  margin-bottom: 4rem;

  .kyc-image {
    width: 6.25rem;
  }
`;

PartnerKyc.propTypes = propTypes;

export default PartnerKyc;