/**
Author - Pradip Dhakal
Git - https://github.com/pradipwealthy
**/

import styled from "styled-components";

const AttentionContainer = styled.p.attrs({
  className: "description2",
})`
  > span {
    color: #ff6b6b;
    font-weight: 600;
  }
  color: #7e7e7e;
  width: 96%;
  margin: 0.5rem auto;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  align-items: center;
`;

const DeleteWrapper = styled.div`
  width: 2rem;
  cursor: pointer;

  :hover {
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  }
`;

const DeleteIcon = styled.img`
  object-fit: "contain";
`;

const DetailWrapper = styled.div`
  margin-left: 1rem;
`;

const Text = styled.p`
  margin: 0;
  color: #193076;
  font-size: 1.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  font-weight: bold;
`;

const ImageSize = styled.p`
  margin: 0;
  color: #193076;
  font-size: 1rem;
`;

const Wrapper = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  height: 100vh;
  margin: 0 auto;
  overflow: none;
`;

const Title = styled.h1.attrs({
  className: "primary-title",
})`
  margin: 0;
  padding: 0;
  color: #000000;
  letter-spacing: 0.1rem;
`;

const ImageCard = styled.div`
  height: 6.5rem;
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
  margin-top: 2rem;
  box-shadow: 0px 3px 8px #d0d0d06e;
  display: flex;
`;

const PreviewImage = styled.img`
  height: 100%;
  width: 5rem;
  object-fit: fill;
  border-radius: 5px;
`;

const SubHeader = styled.p.attrs({
  className: "description2",
})`
  margin: 1rem 0 1rem 0;
  padding: 0;
  color: #7e7e7e;
`;

const ImagesContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UploadImage = styled.img`
  height: 8rem;
`;

const UploadMessage = styled.p`
  margin-left: 2rem;
  text-align: center;
  margin-top: 1rem;
  font-size: 1.4rem;
`;

const ButtonContainer = styled.div`
  flex: 1;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    cursor: pointer;
  }
  flex-direction: column;
  span {
    color: #4a4a4a;
    font-weight: 500;
    font-size: 1.1rem;
    margin-top: 0.5rem;
    cursor: pointer;
  }
`;

const DropZoneContainer = styled(ButtonContainer)`
  border: dashed 2px #5784eb;
  border-radius: 5px;
  cursor: pointer;
  color: #193076;
  height: 30rem;
  width: 50%;
  margin: 0 auto;
  margin-right: 8px;

  > span {
    color: #4a4a4a;
    font-weight: 500;
    font-size: 0.95rem;
    margin-top: 0.5rem;
  }
  i {
    margin-right: 0.5em;
  }
`;

const PasswordInput = styled.input.attrs({
  className: "form-input",
})`
  border: none;
  background: transparent;
  border-bottom: 1px solid #dddddd;
  outline: none;
  padding-bottom: 0.5rem;
  color: #000000;
  width: 100%;
  margin: 1rem 0;
  text-align: center;

  &::placeholder {
    text-align: center;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

const DropContainer = styled.div`
  display: flex;
  @media screen and (max-width: 300px) {
  }
`;

export {
  ActionWrapper,
  AttentionContainer,
  DeleteWrapper,
  DeleteIcon,
  DetailWrapper,
  Text,
  ImageSize,
  Wrapper,
  Title,
  ImageCard,
  PreviewImage,
  SubHeader,
  ImagesContainer,
  Container,
  UploadImage,
  UploadMessage,
  DropZoneContainer,
  PasswordInput,
  DropContainer,
};
