/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import React, {
  useState,
  useEffect,
  useCallback
} from "react";
import PropTypes from "prop-types";

import {
  updatePennyDrop
} from "api";
import {
  handleApiError,
  formValidator
} from "helpers";
import {
  useCheckIfscCode
} from "hooks";
import {
  RudderStackEvent
} from "types";
import {
  Analytics,
  checkIsWebView
} from "utils";

import PartnerBankDetails from "./PartnerBankDetails";

const formFields = ["beneficiaryAccountIfsc", "beneficiaryAccountNo"];

// TODO: ADD LIMIT ON BENEFICIARY ACCOUNT NUMBER FOR 18 DIGITS

const propTypes = {
  kycData: PropTypes.object.isRequired,
  updateStatus: PropTypes.func.isRequired,
  skipPartnerStep: PropTypes.func.isRequired
};

const PartnerBankDetailsContainer = ({
  kycData,
  updateStatus,
  skipPartnerStep
}) => {
  const [formData, setFormData] = useState({
    "beneficiaryAccountIfsc": kycData?.prefill?.beneficiaryAccountIfsc ?? "",
    "beneficiaryAccountNo": kycData?.prefill?.beneficiaryAccountNo ?? ""
  });

  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isRedirectLoading, setRedirectLoading] = useState(false);

  const [isIfscValid, ifscResponse] = useCheckIfscCode(formData.beneficiaryAccountIfsc, setIsLoading);
  const [showSkipButton, setShowSkipButton] = useState(true);

  useEffect(() => {
    if (!kycData?.canUserSkip) {
      setShowSkipButton(false);
    }
  }, [kycData]);

  useEffect(() => {
    if (formData.beneficiaryAccountIfsc?.length === 11 && isIfscValid) {
      setIsDisabled(formValidator(formFields, formData));
    } else {
      setIsDisabled(true);
    }
  }, [formData, isIfscValid]);

  const onChangeHandler = (event) => {
    const {
      name,
      value
    } = event.target;
    const validationObj = {
      beneficiaryAccountIfsc: () => value.length <= 11
    };

    //* checking if name exists in obj and field is validated or not.
    if (validationObj.hasOwnProperty(name) && !validationObj[name]()) {
      return;
    }
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const skipStep = useCallback(() => {
    Analytics.trackForRudder(RudderStackEvent.KYC_BANK_SKIP);
    const redirectLink = window.sessionStorage.getItem("redirectTo");

    if (!showSkipButton && redirectLink !== "null") {
      setRedirectLoading(true);
      setTimeout(() => {
        if(checkIsWebView()){
          window.location.replace(redirectLink);
         } else {
           window.location.replace(redirectLink);
         }
        setRedirectLoading(false);
      }, 1000);
    } else {
      skipPartnerStep();
    }
  }, [showSkipButton]);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const payload = {
        ...formData
      };
      const res = await updatePennyDrop(payload);
      if (res.status === 200) {
        Analytics.trackForRudder(RudderStackEvent.KYC_BANK_DETAILS_SUBMITTED);
        updateStatus();
      }
    } catch (error) {
      Analytics.trackForRudder(RudderStackEvent.KYC_SUBFLOW_FAILED);
      handleApiError(error);
      setTimeout(() => {
        updateStatus();
      }, 4500);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PartnerBankDetails
      formData={formData}
      ifscResponse={ifscResponse}
      isIfscValid={isIfscValid}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isRedirectLoading={isRedirectLoading}
      onChangeHandler={onChangeHandler}
      onSubmit={onSubmit}
      skipStep={skipStep}
      showSkipButton={showSkipButton}
    />
  );
};

PartnerBankDetailsContainer.propTypes = propTypes;

export default PartnerBankDetailsContainer;
