/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React from "react";

import styled from "styled-components";

import {
  ActionButton,
} from "screens/commons";
import IMAGES from "constants/Images";

const BankDetails = ({
  confirmAccountNumber,
  onChangeHandler,
  onSubmit,
  formData,
  isLoading,
  isDisabled,
  isIfscValid,
  ifscResponse,
  isAccountNumberConfirmed,
  accountTypeText
}) => (
  <BasicDetailsWrapper>
    <Container>
      <Title>Bank Details</Title>
      <SubHeader>Type in all your details correctly and recheck</SubHeader>
      {accountTypeText ? <SubHeader className="account-type-txt">
        <InfoImage src={IMAGES.infoIcon} alt="warn" />
        <span className="highlight" dangerouslySetInnerHTML={{
          __html: accountTypeText
        }}>
        </span>
      </SubHeader> : null}
      <InputsContainer>
        <InputContainer>
          <Label>Account Number</Label>
          <Input
            name="beneficiaryAccountNo"
            type="number"
            value={formData.beneficiaryAccountNo}
            onChange={onChangeHandler}
            onBlur={confirmAccountNumber}
          />
        </InputContainer>

        {/* <CustomInput
          label="Confirm Account Number"
          name="confirmAccountNo"
          type="number"
          onChange={onChangeHandler}
          onBlur={confirmAccountNumber}
        >
          {
            isAccountNumberConfirmed === false ? (
              <IfscMessage>Account number does not match, please check.</IfscMessage>
            ) : (
              <></>
            )
          }
        </CustomInput> */}

        <InputContainer>
          <Label>IFSC Code</Label>
          <Input
            name="beneficiaryAccountIfsc"
            value={formData?.beneficiaryAccountIfsc}
            onChange={onChangeHandler}
          />
          {
            !isIfscValid ? (
              <IfscMessage>Invalid IFSC Code, please check.</IfscMessage>
            ) : (
              <IfscAddress>{ifscResponse.address}</IfscAddress>
            )
          }
        </InputContainer>

        <DisclaimerContainer>
          Note: This bank account will be the default account for all your investments and withdrawals
        </DisclaimerContainer>

        <ActionButton
          isDisabled={isDisabled}
          buttonText="Verify & Continue"
          callback={onSubmit}
          isLoading={isLoading}
        />

      </InputsContainer>
      <ExtraSpace />
    </Container>
  </BasicDetailsWrapper>
);

const InfoImage = styled.img`
  height:24px;
  width:24px;
`;

const BasicDetailsWrapper = styled.div`
  height: 100vh;
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;

  @media (max-width: 768px) {
    background: white;
  }
  .account-type-txt{
    display:flex:
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    font-weight:500;
    img {
      margin-right: 1rem;
    }
  }
`;

const ExtraSpace = styled.div`
  height: 250px;
  margin: 10rem 0;
  opacity: 0;
`;

const Container = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  margin: 0 auto;
`;

const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  letter-spacing: 0.1rem;
`;

const SubHeader = styled.p.attrs({
  className: 'description2'
})`
  margin: 1rem 0 0 0;
  padding: 0;
  color: #7E7E7E;
 .highlight {
    mark {
      color: rgb(103, 37, 244);
      font-weight:bold !important;
      background:none;
    }
  }
`;

const InputContainer = styled.div`
  margin-bottom: 3.3rem;

  input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  color: currentColor;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.15em solid lightgrey;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: inline-grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #6725F4;
  display: inline-grid;
  place-content: center;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}
`;

const Label = styled.p.attrs({
  className: 'description2'
})`
  color: #7E7E7E;
`;

const Input = styled.input.attrs({
  className: 'form-input'
})`
  border: none;
  background: transparent;
  border-bottom: 1px solid #DDDDDD;
  outline: none;
  padding-bottom: 0.5rem;
  color: #000000;
  width: 100%;
  text-transform: uppercase;

  ::-webkit-outer-spin-button, ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance:textfield;
`;

const InputsContainer = styled.div`
  margin: 7rem 0 0 0;
`;

const IfscMessage = styled.p`
  color: red;
  font-size: 1.2rem;
  margin-top: 0.5rem;
`;

const IfscAddress = styled(IfscMessage)`
  color: #666666;
`;

const DisclaimerContainer = styled.div`
  color: #7E7E7E;
  font-size: 1.4rem;
  padding: 2rem;
  border-radius: 4px;
`;



export default BankDetails;
