/**
Author - Brijesh Pandey
Git - https://github.com/Brijesh-Pandey
**/

import React, {
useState, useEffect
} from "react";

import SuccessScreen from "./SuccessScreen";

const SuccessScreenContainer = ({
  kycData,
  updateStatus
}) => {
  const [timer, setTimer] = useState(5);
  const [isLoading, setIsLoading] = useState(false);

  const isSubflowPresent = kycData.hasSubFlow && !!kycData.subFlow?.redirectUrl?.length;

  useEffect(() => {
    if (!isSubflowPresent) {
      if (timer === 0) {
        handleRedirection();
      }
      const intervalId = setInterval(() => {
        if(timer > 0) {
          setTimer(timer - 1);
        }
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [timer, kycData]);


  const proposalLink = window.sessionStorage.getItem("redirectTo");

  const handleRedirection = () => {
    if (proposalLink !== "null")
      window.location.replace(proposalLink);
  };

  const handleSubFlow = () => {
    if (kycData.hasSubFlow) {
      const targetUrl = kycData.subFlow?.redirectUrl;
      window.location.replace(targetUrl);
    }
  };

  return (
    <SuccessScreen
      kycData={kycData}
      handleSubFlow={handleSubFlow}
      isLoading={isLoading}
      isSubflowPresent={isSubflowPresent}
      proposalLink={proposalLink}
      timer={timer}
    />
  );
};

export default SuccessScreenContainer;
