/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/



import {
  transformedAxios
} from "./axios";
import Cookies from "js-cookie";

import env from "env";

import {
  ACCESS_TOKEN_COOKIE_KEY
} from "constants/cookie";

const ROOT_URL = env.REST_DOMAIN_URL;


const MAPPED_ENDPOINTS = {
  Cvl: "/web/v0/cvl/",
  Status: "/web/v0/status/",
  Nominee: "/web/v0/nominee/",
  Choice: "/web/v0/choice/",
  UploadPanCard: "/web/v0/digio-pan-upload/",
  PennyDrop: "/web/v0/penny-drop/",
  SpecimenSign: "/web/v0/specimen-sign/",
  Digilocker: "/web/v0/digilocker/",
  Selfie: "/web/v0/selfie/",
  FrontendWebhook: "/web/v0/frontend-webhook/",
  DigioWebhook: "/webhooks/digio/v0/digio-webhook/",
  PersonalDetail: "/web/v0/personal-details/",
  TradingPreferences: "/web/v0/trading-preferences/",
  ESign: "/web/v0/e-sign/",
  DDPI: "/web/v0/ddpi/",
  Ifsc: "/web/v0/ifsc/",
  FNO: "/web/v0/meta/fno-declarations/",
  FnoSkip: "/web/v0/income_declaration/skip/",
  UploadDocument: "/web/v0/income_declaration/",
  PanAnalysis: "/web/v0/pan-analysis/",
  CheckArn: "/web/v0/check-arn/",
  ArnChoice: "/web/v0/arn-choice/",
  UploadArn: "/web/v0/upload-arn/",
  UploadEuin: "/web/v0/upload-euin/",
  PartnerDocumentVerification: "/web/v0/meta-form/",
  EmptyAgreement: "/web/v0/meta-form/agreement/",
  SkipPartnerSubFlow: "/web/v0/skip-step/",
  AgreementDetails: "/web/v0/agreement/",
  SignAgreementDetails: "/web/v0/agreement/accept/",
  Gst: "/web/v0/gst/",
  BankProof:"/web/v0/bank-proof/",
  BankProofMetaData:"/web/v0/meta/bank-proof/",
  Skip: "/web/v0/skip-step/ ",
  Address:"/web/v0/address/",
  AddressProofMetaData:'/web/v0/meta/address-proof/',
  AddressProof:'/web/v0/address_proof/',
  passport:'/web/v0/passport/',
  UploadIds:'/web/v0/upload_ids/',
  PartialReset: '/web/v0/partial-reset/',
};

const getCvlStatus = () => {
  const endPoint = MAPPED_ENDPOINTS.Cvl;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.post(finalUrl);
};

const getKycStatus = () => {
  const endPoint = MAPPED_ENDPOINTS.Status;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.get(finalUrl);
};

const getDigilockerData = () => {
  const endPoint = MAPPED_ENDPOINTS.Digilocker;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.get(finalUrl);
};

const getSpecimenSign = () => {
  const endPoint = MAPPED_ENDPOINTS.SpecimenSign;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.get(finalUrl);
};

const getEsignRequest = () => {
  const endPoint = MAPPED_ENDPOINTS.ESign;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.get(finalUrl);
};
const getDdpiRequest = () => {
  const endPoint = MAPPED_ENDPOINTS.DDPI;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.get(finalUrl);
};

const getSelfieRequest = () => {
  const endPoint = MAPPED_ENDPOINTS.Selfie;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.get(finalUrl);
};

const getPersonalDetailSchema = () => {
  const endPoint = MAPPED_ENDPOINTS.PersonalDetail;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.get(finalUrl);
};

const getTradingPreferencesSchema = () => {
  const endPoint = MAPPED_ENDPOINTS.TradingPreferences;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.get(finalUrl);
};

const getAddressSchema = () => {
  const endPoint = MAPPED_ENDPOINTS.Address;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.get(finalUrl);
};

const getChoiceSchema = () => {
    const endPoint = MAPPED_ENDPOINTS.Choice;
    const finalUrl = `${ROOT_URL}${endPoint}`;
    return transformedAxios.get(finalUrl);
};


const saveAddressData = (address) => {
  const endPoint = MAPPED_ENDPOINTS.Address;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.post(finalUrl,address);
};


const getAddressProofMetaData = () => {
  const endPoint = MAPPED_ENDPOINTS.AddressProofMetaData;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.get(finalUrl);
};
const uploadAddressProof = (formData,docType) => {
  const endPoint = MAPPED_ENDPOINTS.AddressProof;
  const finalUrl = `${ROOT_URL}${endPoint}?doc_type=${docType}`;

  const header = new Headers();
  const authToken = Cookies.get(ACCESS_TOKEN_COOKIE_KEY);

  header.append("Authorization", authToken);

  const requestOptions = {
    method: 'POST',
    body: formData,
    headers: header,
    redirect: 'follow'
  };

  return fetch(finalUrl, requestOptions);
};

const uploadPassport = (docsData) => {
  const endPoint = MAPPED_ENDPOINTS.passport;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  const header = new Headers();
  const authToken = Cookies.get(ACCESS_TOKEN_COOKIE_KEY);

  header.append("Authorization", authToken);

  const requestOptions = {
    method: 'POST',
    body: docsData,
    headers: header,
    redirect: 'follow'
  };

  return fetch(finalUrl, requestOptions);
};

const uploadIds = (docsData) => {
  const endPoint = MAPPED_ENDPOINTS.UploadIds;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  const header = new Headers();
  const authToken = Cookies.get(ACCESS_TOKEN_COOKIE_KEY);

  header.append("Authorization", authToken);

  const requestOptions = {
    method: 'POST',
    body: docsData,
    headers: header,
    redirect: 'follow'
  };

  return fetch(finalUrl, requestOptions);
};

const validateIfscCode = (ifscCode) => {
  const endPoint = MAPPED_ENDPOINTS.Ifsc;
  const finalUrl = `${ROOT_URL}${endPoint}?ifsc=${ifscCode}`;

  return transformedAxios.get(finalUrl);
};

const updateDigioWebhook = (payload) => {
  const endPoint = MAPPED_ENDPOINTS.DigioWebhook;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.post(finalUrl, payload);
};

const updatePersonalDetails = (payload) => {
  const endPoint = MAPPED_ENDPOINTS.PersonalDetail;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.post(finalUrl, payload);
};

const updateTradingPreferences = (payload) => {
  const endPoint = MAPPED_ENDPOINTS.TradingPreferences;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.post(finalUrl, payload);
};

const updatePennyDrop = (payload) => {
  const endPoint = MAPPED_ENDPOINTS.PennyDrop;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.post(finalUrl, payload);
};

const updateNomineeDetails = (payload) => {
  const endPoint = MAPPED_ENDPOINTS.Nominee;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.post(finalUrl, payload);
};

const selectFlowType = (payload) => {
  const endPoint = MAPPED_ENDPOINTS.Choice;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.post(finalUrl, payload);
};

const isArnAttached = () => {
  const endPoint = MAPPED_ENDPOINTS.CheckArn;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.post(finalUrl);
};

const submitArn = () => {
  const endPoint = MAPPED_ENDPOINTS.CheckArn;
  const finalUrl = `${ROOT_URL}${endPoint}?submit_arn=true`;

  return transformedAxios.post(finalUrl);
};

const getPartnerDocumentVerificationSchema = () => {
  const endPoint = MAPPED_ENDPOINTS.PartnerDocumentVerification;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.post(finalUrl);
};

const getEmptyAgreement = () => {
  const endPoint = MAPPED_ENDPOINTS.EmptyAgreement;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.get(finalUrl);
};

const submitEmptyAgreement = () => {
  const endPoint = MAPPED_ENDPOINTS.PartnerDocumentVerification;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.post(finalUrl);
};

const submitPartnerDocumentVerification = (payload) => {
  const endPoint = MAPPED_ENDPOINTS.PartnerDocumentVerification;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.post(finalUrl, payload);
};

const skipPartnerSubFlow = () => {
  const endPoint = MAPPED_ENDPOINTS.SkipPartnerSubFlow;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.post(finalUrl);
};

// const uploadAgreementDetails = (payload) => {
//   const endPoint = MAPPED_ENDPOINTS.AgreementDetails;
//   const finalUrl = `${ROOT_URL}${endPoint}`;

//   return transformedAxios.post(finalUrl, payload);
// };

const getAgreementDetails = (payload) => {
  const endPoint = MAPPED_ENDPOINTS.AgreementDetails;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.get(finalUrl);
};

const uploadGstDetails = (gst) => {
  const endPoint = MAPPED_ENDPOINTS.Gst;
  const finalUrl = `${ROOT_URL}${endPoint}?gst_in=${gst}`;

  return transformedAxios.post(finalUrl);
};

const signAgreementDetails = (requestId) => {
  const endPoint = MAPPED_ENDPOINTS.SignAgreementDetails;
  const finalUrl = `${ROOT_URL}${endPoint}?request_id=${requestId}`;

  return transformedAxios.post(finalUrl);
};
// const uploadPanCard = (formData) => {
//   const endPoint = MAPPED_ENDPOINTS.UploadPanCard;
//   const finalUrl = `${ROOT_URL}${endPoint}`;

//   const header = new Headers();
//   const authToken = Cookies.get(ACCESS_TOKEN_COOKIE_KEY);
//   header.append("Authorization", authToken);

//   const requestOptions = {
//     method: 'POST',
//     body: formData,
//     headers: header,
//     redirect: 'follow'
//   };

//   return fetch(finalUrl, requestOptions);
// };
// BROKING RELATED API'S

const getFnoDeclarations = () => {
  const endPoint = MAPPED_ENDPOINTS.FNO;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.get(finalUrl);
};

const skipStepInKycFlow = () => {
  const endPoint = MAPPED_ENDPOINTS.Skip;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.post(finalUrl);
};

const skipFnoDeclarations = () => {
  const endPoint = MAPPED_ENDPOINTS.FnoSkip;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.post(finalUrl);
};

const uploadDocuments = (formData, queryParam) => {
  const endPoint = MAPPED_ENDPOINTS.UploadDocument;
  const finalUrl = `${ROOT_URL}${endPoint}?doc_type=${queryParam}`;

  // TODO: Create a wrapper of fetch for uploading api's

  const header = new Headers();
  const authToken = Cookies.get(ACCESS_TOKEN_COOKIE_KEY);

  header.append("Authorization", authToken);

  const requestOptions = {
    method: 'POST',
    body: formData,
    headers: header,
    redirect: 'follow'
  };

  return fetch(finalUrl, requestOptions);
};

const getBankProofMetaData = () => {
  const endPoint = MAPPED_ENDPOINTS.BankProofMetaData;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.get(finalUrl);
};

const uploadBankProof = (formData,docType) => {
  const endPoint = MAPPED_ENDPOINTS.BankProof;
  const finalUrl = `${ROOT_URL}${endPoint}?doc_type=${docType}`;

  // TODO: Create a wrapper of fetch for uploading api's

  const header = new Headers();
  const authToken = Cookies.get(ACCESS_TOKEN_COOKIE_KEY);

  header.append("Authorization", authToken);

  const requestOptions = {
    method: 'POST',
    body: formData,
    headers: header,
    redirect: 'follow'
  };

  return fetch(finalUrl, requestOptions);
};

const checkPanAadharSeeded = () => {
  const endPoint = MAPPED_ENDPOINTS.PanAnalysis;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.get(finalUrl);
};

const uploadPanCard = () => {
  const endPoint = MAPPED_ENDPOINTS.UploadPanCard;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.get(finalUrl);
};

const arnChoice = (payload) => {
  const endPoint = MAPPED_ENDPOINTS.ArnChoice;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.post(finalUrl, payload);
};

const uploadArnCard = (formData) => {
  const endPoint = MAPPED_ENDPOINTS.UploadArn;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  const header = new Headers();
  const authToken = Cookies.get(ACCESS_TOKEN_COOKIE_KEY);

  header.append("Authorization", authToken);

  const requestOptions = {
    method: 'POST',
    body: formData,
    headers: header,
    redirect: 'follow'
  };

  return fetch(finalUrl, requestOptions);
};

const uploadEuinCard = (formData) => {
  const endPoint = MAPPED_ENDPOINTS.UploadEuin;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  const header = new Headers();
  const authToken = Cookies.get(ACCESS_TOKEN_COOKIE_KEY);

  header.append("Authorization", authToken);

  const requestOptions = {
    method: 'POST',
    body: formData,
    headers: header,
    redirect: 'follow'
  };

  return fetch(finalUrl, requestOptions);
};

const partialResetFlow = (data) => {
  const endPoint = MAPPED_ENDPOINTS.PartialReset;
  const finalUrl = `${ROOT_URL}${endPoint}`;

  return transformedAxios.post(finalUrl, data);
};

export {
  getCvlStatus,
  getKycStatus,
  getDigilockerData,
  getEsignRequest,
  getDdpiRequest,
  getSpecimenSign,
  getSelfieRequest,
  getPersonalDetailSchema,
  validateIfscCode,
  updatePennyDrop,
  updateNomineeDetails,
  updatePersonalDetails,
  updateTradingPreferences,
  updateDigioWebhook,
  uploadDocuments,
  getFnoDeclarations,
  skipFnoDeclarations,
  selectFlowType,
  checkPanAadharSeeded,
  uploadPanCard,
  isArnAttached,
  getPartnerDocumentVerificationSchema,
  skipPartnerSubFlow,
  uploadGstDetails,
  signAgreementDetails,
  getAgreementDetails,
  submitPartnerDocumentVerification,
  getEmptyAgreement,
  submitEmptyAgreement,
  submitArn,
  getBankProofMetaData,
  uploadBankProof,
  skipStepInKycFlow,
  getAddressSchema,
  getChoiceSchema,
  saveAddressData,
  getAddressProofMetaData,
  uploadAddressProof,
  uploadPassport,
  uploadArnCard,
  uploadEuinCard,
  getTradingPreferencesSchema,
  arnChoice,
  uploadIds,
  partialResetFlow
};