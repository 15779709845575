/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  Spinner,
  Error
} from "../commons";

import DigioFlow from "../DigioFlow";

const propTypes = {
  kycData: PropTypes.object.isRequired,
  updateStatus: PropTypes.func.isRequired,
  handleErrorState: PropTypes.func
};

const InitiateApiCalls = ({
  isError,
  digioDetails,
  kycData,
  updateStatus,
  handleErrorState
}) => (
    <>
      {
        digioDetails && !!digioDetails.kid ? (
          <DigioFlow
            requestId={digioDetails.kid}
            identifier={digioDetails.customerIdentifier}
            tokenId={digioDetails.tokenId}
            updateStatus={updateStatus}
            kycData={kycData}
            handleErrorState={handleErrorState}
          />
        ) : (
          isError ? (
            <ErrorContainer>
              <Error
                message="Please try refreshing, if it still doesn't work, try again after sometime"
              />
            </ErrorContainer>
          ) : (
            <LoaderContainer>
              <Spinner
                color="blue"
                message="Please wait while we fetch corresponding details, We request you to not to close the window for smooth process"
              />
            </LoaderContainer>
          )
        )
      }
    </>
  );

const LoaderContainer = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorContainer = styled.div`
  height: 100vh;
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;
  padding: 2.4rem;
  text-align: center;

  .error-image {
    width: 90%;
    margin-bottom: 5rem;
  }

  @media (max-width: 768px) {
    background:#fff;
  }
`;

InitiateApiCalls.propTypes = propTypes;

export default InitiateApiCalls;
