/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React, {
  useEffect
} from "react";

import styled from "styled-components";

import MetaForm from "@manojadams/metaforms";

const schema = {
  "theme": {
    "type": "mui",
    "sectionLayout": "stepper",
    "mui": {
      "size": "medium",
      "variant": "standard"
    }
  },
  "fields": [
    {
      "name": "page 1",
      "meta": {
        "type": "section"
      },
      "fields": [
        {
          "name": "full_name",
          "meta": {
            "displayName": "FULL NAME",
            "displayType": "text_field",
            "value": "sample name",
            "isDisabled": true,
            "className": "input-container",
            "displayProps": {
              "md": 6
            }
          }
        },
        {
          "name": "fathers_name",
          "meta": {
            "displayName": "FATHERS NAME",
            "className": "input-container",
            "displayProps": {
              "md": 6
            }
          }
        },
        {
          "name": "mothers_name",
          "meta": {
            "displayName": "MOTHERS NAME",
            "className": "input-container",
            "displayProps": {
              "md": 6
            }
          }
        }
      ]
    },
    {
      "name": "page 2",
      "meta": {
        "type": "section"
      },
      "fields": [
        {
          "name": "gender",
          "meta": {
            "displayName": "GENDER",
            "displayType": "select",
            "className": "input-container",
            "options": [
              {
                "value": "M",
                "label": "Male"
              },
              {
                "value": "F",
                "label": "Female"
              }
            ],
            "displayProps": {
              "md": 6
            }
          }
        },
        {
          "name": "marital_status",
          "meta": {
            "displayType": "select",
            "displayName": "MARITAL STATUS",
            "className": "input-container",
            "options": [
              {
                "value": "01",
                "label": "Married"
              },
              {
                "value": "02",
                "label": "Unmarried"
              }
            ],
            "displayProps": {
              "md": 6
            }
          }
        },
        {
          "name": "occupation",
          "meta": {
            "displayType": "select",
            "displayName": "OCCUPATION",
            "className": "input-container",
            "options": [
              {
                "value": "01",
                "label": "PRIVATE SECTOR SERVICE"
              },
              {
                "value": "02",
                "label": "PUBLIC SECTOR"
              },
              {
                "value": "03",
                "label": "BUSINESS"
              },
              {
                "value": "04",
                "label": "PROFESSIONAL"
              },
              {
                "value": "05",
                "label": "AGRICULTURIST"
              },
              {
                "value": "06",
                "label": "RETIRED"
              },
              {
                "value": "07",
                "label": "HOUSEWIFE"
              },
              {
                "value": "08",
                "label": "STUDENT"
              },
              {
                "value": "09",
                "label": "FOREX DEALER"
              },
              {
                "value": "10",
                "label": "GOVERNMENT SERVICE"
              },
              {
                "value": "11",
                "label": "PUBLIC SECTOR / GOVERNMENT SERVICE"
              },
              {
                "value": "99",
                "label": "OTHERS"
              }
            ],
            "displayProps": {
              "md": 6
            }
          }
        },
        {
          "name": "gross_annual_income",
          "meta": {
            "displayType": "select",
            "displayName": "GROSS ANNUAL INCOME",
            "className": "input-container",
            "options": [
              {
                "value": "01",
                "label": "BELOW 1 LAC"
              },
              {
                "value": "02",
                "label": "1-5 LAC"
              },
              {
                "value": "03",
                "label": "5-10 LAC"
              },
              {
                "value": "04",
                "label": "10-25 LAC"
              },
              {
                "value": "05",
                "label": "> 25 LAC"
              },
              {
                "value": "06",
                "label": "25 LAC - 1 CR"
              },
              {
                "value": "07",
                "label": ">1 CR"
              },
              {
                "value": "10",
                "label": "UPTO RS.5,00,000"
              },
              {
                "value": "11",
                "label": "RS.5,00,001 TO RS.25,00,000"
              },
              {
                "value": "12",
                "label": "RS.5,00,00,001 AND ABOVE"
              },
              {
                "value": "13",
                "label": "RS.25,00,001 TO RS.1,00,00,000"
              },
              {
                "value": "14",
                "label": "RS.1,00,00,001 TO RS.5,00,00,000"
              },
              {
                "value": "15",
                "label": "UPTO RS.50,00,000"
              },
              {
                "value": "16",
                "label": "RS.50,00,001 TO RS.2,50,00,000"
              },
              {
                "value": "17",
                "label": "RS.2,50,00,001 TO RS.10,00,00,000"
              },
              {
                "value": "18",
                "label": "RS.10,00,00,001 TO RS.50,00,00,000"
              },
              {
                "value": "19",
                "label": "RS.50,00,00,001 AND ABOVE"
              }
            ],
            "displayProps": {
              "md": 6
            }
          }
        }
      ]
    }
  ],
  "buttons": [
    {
      "name": "previous",
      "meta": {
        "type": "previous",
        "displayName": "Previous",
        "className": "prev-action-btn"
      }
    },
    {
      "name": "next",
      "meta": {
        "type": "next",
        "displayName": "Next",
        "className": "action-btn"
      }
    },
    {
      "name": "submit",
      "meta": {
        "type": "submit",
        "displayName": "Save",
        "className": "action-btn"
      }
    }
  ]
};

const PersonalDetails = ({
  formSchema,
  handleFormSubmit
}) => {
  useEffect(() => {
    const stepperContainer = document.querySelector('[class*="meta-form-stepper"]');

    if (!!stepperContainer) {
      stepperContainer.classList.add("stepper");
    }
  }, []);

  return (
    <Container>
      <Wrapper>
        <Title>Personal Details</Title>
        <SubHeader>Please fill in your personal details</SubHeader>
        <FormWrapper>
          <MetaForm
            schema={formSchema}
            onSubmit={(data) => {
              handleFormSubmit(data);
            }}
          />
        </FormWrapper>
        <ExtraSpace />
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;

  @media (max-width: 768px) {
    background: white;
  }
`;

const Wrapper = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  margin: 0 auto;
`;

const ExtraSpace = styled.div`

  height: 250px;
  margin: 10rem 0;
  opacity: 0;
  @media screen and (max-width: 768px) {
    height:0;
  }
`;


const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  letter-spacing: 0.1rem;
`;

const SubHeader = styled.p.attrs({
  className: 'description2'
})`
  margin: 1rem 0 0 0;
  padding: 0;
  color: #7E7E7E;
`;

const FormWrapper = styled.div`
  .stepper {
    margin: 2rem;
    opacity: 0;
  }

  .input-container {
    margin-bottom: 3.3rem;
  }

  .input-container label {
    font-size: 1.4rem;
    font-weight: 400;
    color: #7E7E7E;
  }

  .input-container label+div {
    font-size: 1.6rem;
    font-weight: 400;
    color: #000000;
  }

  .input-container input {
    border: none;
    background: transparent;
    border-bottom: 1px solid #DDDDDD;
    outline: none;
    padding-bottom: 0.5rem;
    color: #000000;
    font-size: 1.6rem;
    font-family: MavenPro;
    font-weight: 500;
    width: 100%;
    text-transform: uppercase;
  }

  .action-btn {
    padding: 2rem 0;
    background: #6725F4;
    border-radius: 52px;
    width: 100%;
    margin-top: 3rem;
    color: #ffffff;
    font-size: 1.6rem;
    font-family: MavenPro;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: scale 300ms;

    :hover {
      background-color: #6725F4;
      scale: 1.02;
    }
  }

  .prev-action-btn {
    padding: 2rem 0;
    background: transparent;
    color: #6725F4;
    border-radius: 52px;
    width: 100%;
    margin-top: 3rem;
    font-size: 1.6rem;
    font-family: MavenPro;
    font-weight: 500;
    display: flex;
    justify-content: left;
    align-items: center;

    :hover {
      background: transparent;
    }
  }
`;

export default PersonalDetails;
