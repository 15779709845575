import React, {
 useState
} from "react";
import styled, {
 css
} from "styled-components";
import IMAGES from "constants/Images";


const Card = styled.div`
  position: relative;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 550px;
  height: 180px;
  padding-left: 12px;
  padding-right: 12px;
  margin: 16px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease, background-color 0.2s ease, color 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 20px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.4);
  }

  ${(props) =>
    props.selected &&
    css`
      background-color: #007bff;
      color: #fff;
      box-shadow: none;

      ${Label} {
        color: #fff;
        font-weight: bolder;
      }

      ${InfoIcon} {
        color: #fff;
      }

      ${SubLabel} {
        color: #ddd;
      }
    `}
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  margin-bottom: 16px;
`;

const SubLabel = styled.div`
  margin-top: 8px;
  color: #666;
  font-size: 1.3rem;
`;

const InfoIcon = styled.img`
    height: 16px;
    width: 16px;
    margin-left: 4px;
    color: #666;
`;

const SuccessIcon = styled.img`
    position: absolute;
    top: 20px;
    right: 20px;
    display: ${(props) => (props.selected ? "block" : "none")};
    height: 20px;
    width: 20px;
`;

const Tooltip = styled.div`
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  background-color: #000;
  color: #fff;
  text-align: left;
  border-radius: 4px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s;
  font-size: 1rem;
`;

const RadioButtonCard = ({
  label,
  subLabel,
  instruction,
  checked,
  onClick,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  return (
    <Card selected={checked} onClick={onClick}>
      <Label>
        {label}
        {instruction && (
          <div
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
          >
            <InfoIcon src={IMAGES.infoIconImage} selected={checked} />
            <Tooltip visible={tooltipVisible}>{instruction}</Tooltip>
          </div>
        )}
      </Label>
      <hr />
      {subLabel && <SubLabel dangerouslySetInnerHTML={{__html: subLabel}} />}
      <SuccessIcon src={IMAGES.successIconImage} selected={checked} />
    </Card>
  );
};

export default RadioButtonCard;
