const IMAGES = {
    profileCardSearch : "https://i.wlycdn.com/articles/profile-card-search.svg",
    infoIcon:"https://i.wlycdn.com/articles/info-icon-light-purple-ico.svg",
    playStore:"https://i.wlycdn.com/articles/play-store-with-theme-bg.svg",
    appStore:"https://i.wlycdn.com/articles/app-store-with-theme-bg.svg",
    wealthyLogo:"https://i.wlycdn.com/articles/wealthy-logo-light-purple.svg",
    mainColorWealthyLogo:"https://i.wlycdn.com/wealthy-home-page/wealthy-new-purple-logo.svg",
    infoIconThemeColor:"https://i.wlycdn.com/family-flow/crn-info-icon.svg",
    successIconImage:"https://i.wlycdn.com/wealthy-kyc/kyc-success-tick.svg",
    infoIconImage:"https://i.wlycdn.com/family-flow/crn-info-icon.svg",
    fileUploadImage:"https://i.wlycdn.com/wealthy-home-page/upload-cloud-icon.png",
    deleteImage:"https://i.wlycdn.com/wealthy-home-page/partner-close-button.svg",
    crossIcon:"https://i.wlycdn.com/articles/cross-icon.svg"
};

export default IMAGES;