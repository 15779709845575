/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import React, {
  useState,
  useEffect,
  useCallback
} from "react";
import PropTypes from "prop-types";

import {
  signAgreementDetails,
  getAgreementDetails
} from "api";

import {
  handleApiError
} from "helpers";
import {
  RudderStackEvent
} from "types";
import {
  isValidHttpUrl,
  Analytics
} from "utils";

import PartnerAgreement from "./PartnerAgreement";

const propTypes = {
  skipPartnerStep: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
  kycData: PropTypes.object.isRequired,
};

const PartnerAgreementContainer = ({
  skipPartnerStep,
  updateStatus,
  kycData
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [agreementDetails, setAgreementDetails] = useState({});
  const {docUrl, requestId} = agreementDetails;
  const [showSkipButton, setShowSkipButton] = useState(true);

  useEffect(() => {
    fetchAgreementDetails();
    if (!kycData?.canUserSkip) {
      setShowSkipButton(false);
    }
  }, [kycData]);

  const fetchAgreementDetails = async () => {
    try {
      setIsLoading(true);
      const {data: {response}} = await getAgreementDetails();

      let agreementObj = {
        docUrl: response.docUrl,
        requestId: response.requestId
      };

      if (isValidHttpUrl(response.docUrl)) {
        agreementObj = {
          docUrl: response.docUrl,
          requestId: response.requestId
        };
      } else {
        agreementObj = {
          requestId: response.requestId
        };
      }
      setAgreementDetails(agreementObj);
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const skipStep = useCallback(() => {
    Analytics.trackForRudder(RudderStackEvent.KYC_DOCUMENT_SKIP);
    const redirectLink = window.sessionStorage.getItem("redirectTo");

    if (!showSkipButton && redirectLink !== "null") {
      setIsLoading(true);

      setTimeout(() => {
        window.location.replace(redirectLink);
        setIsLoading(false);
      }, 1000);
    } else {
      skipPartnerStep();
    }
  }, [showSkipButton]);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const res = await signAgreementDetails(requestId);
      if (res.status === 200) {
        Analytics.trackForRudder(RudderStackEvent.KYC_DOCUMENT_SIGN);
        updateStatus();
      }
    } catch (error) {
      Analytics.trackForRudder(RudderStackEvent.KYC_SUBFLOW_FAILED);
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PartnerAgreement
      onSubmit={onSubmit}
      skipStep={skipStep}
      agreementUrl={docUrl}
      isLoading={isLoading}
      showSkipButton={showSkipButton}
    />
  );
};

PartnerAgreementContainer.propTypes = propTypes;

export default PartnerAgreementContainer;
