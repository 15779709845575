/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

const getTodaysDate = () => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const todaysDate = `${year}-${month}-${day}`;

  return todaysDate;
};

export default getTodaysDate;
