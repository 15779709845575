/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

const validateFormField = {
  default: (data = "") => data && data.length >= 1,
  city: (city) => {
    const cityRegex = /^[\s\S]{1,50}$/;
    return cityRegex.test(city);
  },
  phone: (phone) => {
    const indianPhoneNumberRegex = /^[6-9]\d{9}$/gi;
    return indianPhoneNumberRegex.test(phone);
  },
  email: (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },
  name: (name) => {
    const trimmedName = name.trim();
    const nameRegex = /^[a-zA-Z ]{1,100}$/;
    return nameRegex.test(trimmedName);
  },
  panGeneric: (pan) => {
    const panRegex = /^([a-zA-Z]{3})(P|C|T|F|H)([a-zA-Z]{1})([0-9]{4})([a-zA-Z]{1})$/;
    return panRegex.test(pan);
  },
  gstGeneric: (gst) => {
    const gstRegex = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
    return gstRegex.test(gst);
  },
  aadhar: (aadhar) => {
    const regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
    return regex.test(aadhar);
  },
  amount: (amount) => amount > 0,
  none: () => true
};

const WealthyValidations = {
  validateFormField
};

export default WealthyValidations;
