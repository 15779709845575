/**
Author - Brijesh Pandey
Git - https://github.com/Brijesh-Pandey
**/

import {
useEffect
} from "react";

const useOnClickOutside = (ref, clickHandler) => {
  useEffect(() => {
    document.addEventListener("mousedown", checkClickOutside);

    return () => {
      document.removeEventListener("mousedown", checkClickOutside);
    };
  }, [ref, clickHandler]);

  const checkClickOutside = (event) => {
    if (ref && ref.current && !ref.current.contains(event.target)) {
      clickHandler();
    }
  };
};

export default useOnClickOutside;