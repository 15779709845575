/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  FlowType
} from "../../../enums";

import {
  Spinner,
  ActionButton
} from "../../commons";

const propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPanAadharSeeded: PropTypes.bool.isRequired,
  isFetchingPanAadharSeeded: PropTypes.bool.isRequired
};

const CheckDigiFlow = ({
  handleFormSubmit,
  isLoading,
  isPanAadharSeeded,
  isFetchingPanAadharSeeded
}) => {
  const loadingMessage = isFetchingPanAadharSeeded ? "Checking whether your Pan is linked with Aadhar Card, Please do not close the window" : "Updating your information, Please do not close the window";

  return isFetchingPanAadharSeeded || isLoading ? (
    <LoaderContainer>
      <Spinner
        color="blue"
        message={loadingMessage}
      />
    </LoaderContainer>
  ) : (
    <Container>
      <Wrapper>
        <Title isMarginBelow>Please select a KYC option</Title>
        <ContentWrapper>
          {
            isPanAadharSeeded ? (
              <>
                <Card
                  onClick={() => handleFormSubmit(FlowType.Digilocker)}
                >
                  <Header>
                    <Title>DigiLocker</Title>
                    <Badge>
                      Recommended
                    </Badge>
                  </Header>
                  <SubHeader>Select this option if you have access to the phone number linked with your Aadhar</SubHeader>
                  <ActionButton
                    buttonText="Choose Digilocker"
                    callback={() => { }}
                    isDisabled={isLoading}
                  />
                </Card>
                <Break>
                  <HorizontalLine />
                  <Description>or</Description>
                  <HorizontalLine />
                </Break>
              </>
            ) : <></>
          }
          <Card
            onClick={() => handleFormSubmit(FlowType.Manual)}
          >
            <Title>Manual KYC</Title>
            {
              isPanAadharSeeded ? (
                <SubHeader>
                  Select this option if you do not have access to the phone number linked with your Aadhar. Please have your PAN ready if you choose this option.
                </SubHeader>
              ) : <></>
            }
            <ActionButton
              buttonText="Choose Manual KYC"
              callback={() => { }}
              isDisabled={isLoading}
            />
          </Card>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  margin: 0 auto;
`;

const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  color: #000000;
  letter-spacing: 0.1rem;
  align-self: center;
  margin-bottom: ${props => props.isMarginBelow ? '5rem' : '0rem'};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SubHeader = styled.p.attrs({
  className: 'description2'
})`
  margin: 2rem 0 2rem 0;
  padding: 0;
  color: #7E7E7E;
`;

const Badge = styled.div.attrs({
  className: 'caption2'
})`
  color: #6725F4;
  border: 1px solid #6725F4;
  align-self: end;
  border-radius: 4px;
  padding: 4px 6px;
  margin-left: 1.5rem;
`;

const LoaderContainer = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Break = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 4rem 0rem;
  width: 100%;
`;

const HorizontalLine = styled.div`
  height: 1px;
  width: 100%;
  background: #E3E3E3;
`;

const Description = styled.div.attrs({
  className: 'description2'
})`
  color: #7E7E7E;
  margin: 0rem 2rem
`;

const Card = styled.div`
  width: 90%;
  margin-top :${props => props.marginTop || '0rem'};
  margin-bottom :${props => props.marginBottom || '0rem'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform .2s;
  align-items: center;
  background: #F8F4FF;
  border-radius: 0.75rem;
  padding: 2rem 2rem;
  cursor: pointer;
  max-height: 40rem;

  @media (max-width: 1200px) {
    width: 100%;
  }

  :hover {
    transform: scale(1.01);
  }
`;

CheckDigiFlow.propTypes = propTypes;

export default CheckDigiFlow;
