/**
Author - Brijesh Pandey
Git - https://github.com/Brijesh-Pandey
**/

import React from 'react';
import styled from 'styled-components';

const Toggler = ({
  values,
  toggle,
  switchToggle
}) => (

  <SwitchWrapper>
    <span className={!toggle ? 'active' : ''}>{values[0]}</span>
    <div
      role="presentation"
      onClick={() => switchToggle(!toggle)}
      className={`toggler ${toggle ? 'toggled' : ''}`}
    />
    <span className={toggle ? 'active' : ''}>{values[1]}</span>
  </SwitchWrapper>
);


const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 15px;
  padding-top: 15px;
  scale: 0.8;

  span {
    padding: 0 10px;
    font-weight: 500;
  }

  .active {
    color: #6725f4;
  }

  .toggler {
    cursor: pointer;
    position: relative;
    width: 54px;
    height: 23px;
    background: rgba(103, 37, 244, 0.07);
    border: 1px solid #6725f4;
    border-radius: 50px;
    transition: all 0.2s ease-in-out;

    &::after,
    &::before {
      position: absolute;
      content: '';
      border-radius: 100%;
      top: 0;
      bottom: 0;
      margin: auto;
      transition: all 0.2s ease-in-out;
    }

    &::after {
      height: 17px;
      width: 17px;
      background-color: #6725f4;
      left: 3px;
    }

    &::before {
      height: 5px;
      width: 5px;
      background-color: #fff;
      left: 9px;
      z-index: 1;
    }

    @media (max-width: 576px) {
      width: 39px;
      height: 16px;

      &::after {
        height: 13px;
        width: 13px;
      }

      &::before {
        left: 7px;
      }
    }
  }

  .toggled {
    &::after {
      left: calc(100% - 3px);
      transform: translateX(-100%);

      @media (max-width: 576px) {
        left: calc(100% - 2px);
      }
    }

    &::before {
      left: calc(100% - 9px);
      transform: translateX(-100%);

      @media (max-width: 576px) {
        left: calc(100% - 6px);
      }
    }
  }
`;

export default Toggler;
