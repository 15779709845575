/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/


import React from "react";
import PropTypes from "prop-types";

import env from "env";
import {
  triggerToast, handleApiError
} from "helpers";

import {
  updateDigioWebhook
} from "api";
import {
  pollingUpdateStatus
} from "hooks";

const propTypes = {
  requestId: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  tokenId: PropTypes.string.isRequired,
  updateStatus: PropTypes.func.isRequired,
  handleErrorState: PropTypes.func,
  handleLoading: PropTypes.func.isRequired,
  setMountDigioComponent: PropTypes.func.isRequired
};

const DigioInitialization = ({
  requestId,
  identifier,
  tokenId,
  handleLoading,
  updateStatus,
  setMountDigioComponent,
  handleErrorState
}) => {

  const options = {
    environment: env.IS_PRODUCTION ? "production" : "sandbox",
    Is_redirection_approach: true,
    callback: (digioResponse) => {
      if (digioResponse.hasOwnProperty("error_code")) {
        triggerToast(digioResponse.message);
        let error = true;
        handleLoading(error);
      } else {
        setMountDigioComponent(false);
        HandleDigioDataSubmission();
      }
    },
    is_iframe: false,
    logo: "https://i.wlycdn.com/wealthy-home-page/wealthy-logo-purple.png",
    theme: {
      primaryColor: "#AB3498",
      secondaryColor: "#000000"
    }
  };

  const digio = new window.Digio(options);
  digio.init();
  digio.submit(requestId, identifier, tokenId);

  const HandleDigioDataSubmission = async () => {
    try {
      const kycId = window.sessionStorage.getItem("kycId");
      const stage = window.sessionStorage.getItem("stage");
      const payload = {
        "event": "kyc.action.completed",
        "payload": {
          "kyc_request": {
            "transaction_id": `${kycId}-${stage}`
          }
        }
      };
      const res = await updateDigioWebhook(payload);
      await pollingUpdateStatus(updateStatus, handleLoading, handleErrorState);
    } catch (error) {
      handleApiError(error);
    }
  };

  return (
    <></>
  );
};

DigioInitialization.propTypes = propTypes;

export default DigioInitialization;
