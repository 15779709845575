/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import styled from "styled-components";

const PartnerBankDetailsWrapper = styled.div`
  height: 100vh;
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;
`;

const Container = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 30%;
`;

const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  letter-spacing: 0.1rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: start;
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderText = styled.div.attrs({
  className: 'caption'
})`
  margin: 0;
  padding: 0;
  color: #6725F4;
  letter-spacing: 0.1rem;
  align-self: end;
  cursor: pointer;
`;

const SubHeader = styled.p.attrs({
  className: 'description2'
})`
  margin: 1rem 0 0 0;
  padding: 0;
  color: #7E7E7E;
`;

const GstInputContainer = styled.div`
  margin-bottom: 3.3rem;
  display: flex;
  flex-direction: row;
`;

const GstLabel = styled.label`
  font-size: 1.5rem;
  font-weight: 400;
  color: #7E7E7E;
  cursor: pointer;
`;

const CheckBoxInput = styled.input.attrs({
  className: 'form-input'
})`
  border: none;
  background: transparent;
  border-bottom: 1px solid #DDDDDD;
  outline: none;
  padding-bottom: 0.5rem;
  color: #000000;
  margin-right: 1rem;
  cursor: pointer;
  accent-color: #6725F4;
`;

const SubmitButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5rem;
`;
const InputsContainer = styled.div`
  margin: 7rem 0 0 0;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 1.2rem;
  margin-top: 0.5rem;
`;

const IfscAddress = styled(ErrorMessage)`
  color: #666666;
`;

const LoaderContainer = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  PartnerBankDetailsWrapper,
  Container,
  Title,
  SubHeader,
  GstInputContainer,
  GstLabel,
  CheckBoxInput,
  SubmitButtonsWrapper,
  InputsContainer,
  ErrorMessage,
  IfscAddress,
  Header,
  HeaderText,
  FormContent,
  LoaderContainer
};
