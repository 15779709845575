/**
Author - Pradip Dhakal
Git - https://github.com/pradipwealthy
**/

import React from "react";
import Dropzone from "react-dropzone";

import PropTypes from "prop-types";

import {
    AttentionContainer,
    ActionWrapper,
    DeleteWrapper,
    DeleteIcon,
    DetailWrapper,
    Text,
    ImageSize,
    Wrapper,
    Title,
    ImageCard,
    PreviewImage,
    SubHeader,
    ImagesContainer,
    Container,
    UploadImage,
    UploadMessage,
    DropZoneContainer,
    DropContainer,
} from "./UploadDocuments.style";

import {
    ActionButton
} from "screens/commons";
import IMAGES from "constants/Images";

/*
NOTE: Description of the props
commonInstructions: any instructions including html tags
actionButton: {
    buttonText: "text of the button",
    callback: "function to call when the button is clicked",
    isLoading: "boolean to show loading state",
    isDisabled: "boolean to disable the button"
}
documents: [
    {
        "heading": "heading of the document",
        "subHeading": "sub heading of the document",
        "uploadMessage": "message to show when the document is uploaded",
        "multiple": "boolean to allow multiple files",
        "name": "name of the document",
        "onDrop": "function to call when the document is dropped",
        "group": "group of the document" <- based on the group, the document will be shown in the same group
    }
]
handleDeleteUploadedFile - function to handle delete uploaded file
uploadedFiles - uploaded files
acceptFiles - supported file types format: {
    "mimeType": [".extension"],
}
*/
const propTypes = {
    commonInstructions: PropTypes.string,
    actionButton: PropTypes.object,
    documents: PropTypes.array,
    acceptFiles: PropTypes.object,
};


const UploadMultipleDocuments = ({
    commonInstructions = "",
    actionButton = {
        buttonText: "Upload & Continue",
        callback: () => { },
        isLoading: false,
        isDisabled: false,
    },
    documents = [],
    handleDeleteUploadedFile = () => { },
    uploadedFiles = [],
    acceptFiles={
        "image/png": [".png"],
        "image/jpeg": [".jpg", ".jpeg"],
        "application/pdf": [".pdf"],
    }
}) => {
    const groupedDocuments = documents.reduce((acc, doc) => {
        const group = doc.group;
        acc[group] = acc[group] || [];
        acc[group].push(doc);
        return acc;
    }, {});
    return (
        <>
            <Wrapper>
                {Object.keys(groupedDocuments).map((key) => {
                    const value = groupedDocuments[key];
                    return (
                        <div style={{marginBottom: "2rem"}}>
                            <Title>{value[0].heading}</Title>
                            <SubHeader>{value[0].subHeading}</SubHeader>
                            <DropContainer>
                                {value?.map((item) => (
                                    <>
                                        <DropZoneContainer>
                                            <Dropzone
                                                onDrop={(file) => item?.onDrop(file, item.name)}
                                                multiple={item.multiple}
                                                accept={acceptFiles}
                                            >
                                                {({getRootProps, getInputProps}) => (
                                                    <section>
                                                        <div {...getRootProps()}>
                                                            <Container>
                                                                <UploadImage src={IMAGES.fileUploadImage} />
                                                            </Container>
                                                            <input {...getInputProps()} />
                                                            <UploadMessage dangerouslySetInnerHTML={{__html: item?.uploadMessage}} />
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </DropZoneContainer>
                                    </>
                                ))}
                            </DropContainer>
                            {value?.map(item => (
                                    <ImagesContainer>
                                            {uploadedFiles?.map(
                                                (eachFile) =>
                                                    [item.name].includes(eachFile?.id) && (
                                                        <ImageCard>
                                                            <PreviewImage src={URL.createObjectURL(eachFile)} />
                                                            <DetailWrapper>
                                                                <Text>{eachFile.name}</Text>
                                                                <ImageSize>{eachFile.size * 0.001}Kb</ImageSize>
                                                            </DetailWrapper>
                                                            <ActionWrapper>
                                                                <DeleteWrapper
                                                                    onClick={() => handleDeleteUploadedFile(eachFile)}
                                                                >
                                                                    <DeleteIcon alt="delete image" src={IMAGES.deleteImage} />
                                                                </DeleteWrapper>
                                                            </ActionWrapper>
                                                        </ImageCard>
                                                    )
                                            )}
                                        </ImagesContainer>
                                ))}
                        </div>
                    );
                })}

                {commonInstructions &&
                    <AttentionContainer>
                        <span>Attention:</span>
                        <div dangerouslySetInnerHTML={{__html: commonInstructions}} />
                    </AttentionContainer>
                }
                <br />
                <ActionButton
                    buttonText={actionButton?.buttonText}
                    callback={actionButton?.callback}
                    isLoading={actionButton?.isLoading}
                    isDisabled={actionButton?.isDisabled}
                />
            </Wrapper>
        </>
    );
};

UploadMultipleDocuments.propTypes = propTypes;

export default UploadMultipleDocuments;
