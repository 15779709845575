/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React, {
  useState, useEffect
} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import {
  getPartnerDocumentVerificationSchema,
  submitPartnerDocumentVerification,
  getEmptyAgreement,
  submitEmptyAgreement
} from "api";
import {
  RudderStackEvent
} from "types";
import {
  Analytics,
} from "utils";
import {
  handleApiError
} from "helpers";

import {
  Spinner
} from "../../commons";

import DocumentVerification from "./DocumentVerification";
import PartnerEmptyAgreement from "./components/PartnerEmptyAgreement";

const propTypes = {
  updateStatus: PropTypes.func.isRequired,
  skipPartnerStep: PropTypes.func.isRequired,
  kycData: PropTypes.object.isRequired
};

const DocumentVerificationContainer = ({
  updateStatus,
  skipPartnerStep,
  kycData
}) => {
  const [formSchema, setFormSchema] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [agreementUrl, setAgreementUrl] = useState("");

  useEffect(() => {
    if (kycData.stepStatus === "INCOMPLETE") {
      fetchAgreementUrl();
    } else if (kycData.stepStatus === "PENDING") {
      fetchSchema();
    }
  }, [kycData]);

  const fetchAgreementUrl = async () => {
    try {
      setIsLoading(true);
      const {data} = await getEmptyAgreement();
      setAgreementUrl(data.response?.docUrl);
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchSchema = async () => {
    try {
      setIsLoading(true);
      const {data} = await getPartnerDocumentVerificationSchema();
      setFormSchema(data.response?.schema);
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const skipStep = () => {
    skipPartnerStep();
  };

  const handleFormSubmit = async (data) => {
    try {
      setIsLoading(true);
      const payload = {
        ...data,
        full_name: data.full_name?.toUpperCase(),
        arn: data.arn?.toUpperCase(),
        address: data.address?.toUpperCase(),
        email: data.email?.toUpperCase(),
        location: data.location?.toUpperCase(),
      };

      const res = await submitPartnerDocumentVerification(payload);

      if (res.status === 200) {
        Analytics.trackForRudder(RudderStackEvent.KYC_FORM_SUBMIT);
        updateStatus();
      }
    } catch (error) {
      Analytics.trackForRudder(RudderStackEvent.KYC_SUBFLOW_FAILED);
      handleApiError(error);
    } finally {
      setIsLoading(false);
    };
  };

  const onAgreementSubmit = async () => {
    try {
      setIsLoading(true);
      const res = await submitEmptyAgreement();
      if (res.status === 200) {
        updateStatus();
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  let component = <></>;

  switch (kycData.stepStatus) {
    case "INCOMPLETE":
      component = (
        <PartnerEmptyAgreement
          onSubmit={onAgreementSubmit}
          agreementUrl={agreementUrl}
          isLoading={isLoading}
        />
      );
      break;
    case "PENDING":
      component = (
        <DocumentVerification
          formSchema={formSchema}
          handleFormSubmit={handleFormSubmit}
          skipStep={skipStep}
        />
      );
      break;
    default:
      component = (
        <LoaderContainer>
          <Spinner
            color="blue"
            message="Updating your information, Please do not close the window"
          />
        </LoaderContainer>
      );
      break;
  }

  return (
    isLoading ? (
      <LoaderContainer>
        <Spinner
          color="blue"
          message="Updating your information, Please do not close the window"
        />
      </LoaderContainer>
    ) : (
      component
    )
  );
};

const LoaderContainer = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

DocumentVerificationContainer.propTypes = propTypes;

export default DocumentVerificationContainer;
