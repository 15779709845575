/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React from "react";

import styled, {
  css
} from "styled-components";

import {
  ActionButton,
  BackButton
} from "screens/commons";

const PersonalDetails = ({
  checkField,
  checkedState,
  onChangeHandler,
  handleOnCheckboxChange,
  formData,
  onSubmit
}) => (
  <PersonalDetailsWrapper>
    <Container>
      <BackButton />
      <Title>Personal Details</Title>
      <SubHeader>Confirm your personal details</SubHeader>
      <InputsContainer>
        <InputContainer>
          <Label>PAN</Label>
          <Input
            name="pan"
            value={formData.pan}
            onChange={onChangeHandler}
          />
        </InputContainer>

        <InputContainer>
          <Label>Date of birth</Label>
          <Input
            type="date"
            name="dob"
            value={formData.dob}
            onChange={onChangeHandler}
          />
        </InputContainer>

        {
          checkField.map(({name}, index) => (
            <CheckBoxContainer>
              <CheckBox
                type="checkbox"
                name={name}
                value={name}
                checked={checkedState[index]}
                onChange={() => handleOnCheckboxChange(index)}
              />
              <CheckboxText>{name}</CheckboxText>
            </CheckBoxContainer>
          ))
        }

        <ActionButton
          buttonText="Confirm"
          callback={onSubmit}
        />

      </InputsContainer>
    </Container>
  </PersonalDetailsWrapper>
);

const PersonalDetailsWrapper = styled.div`
  height: 100vh;
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;
  background: #ffffff;

  @media (max-width: 768px) {
    background: white;
  }
`;

const Container = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  margin: 0 auto;
`;

const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  color: #000000;
  letter-spacing: 0.1rem;
`;

const SubHeader = styled.p.attrs({
  className: 'description2'
})`
  margin: 1rem 0 0 0;
  padding: 0;
  color: #7E7E7E;
`;

const InputContainer = styled.div`
  margin-bottom: 3.3rem;
`;

const Label = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
  color: #7E7E7E;
`;

const Input = styled.input.attrs({
  className: 'form-input'
})`
  border: none;
  background: transparent;
  border-bottom: 1px solid #DDDDDD;
  outline: none;
  padding-bottom: 0.5rem;
  color: #000000;
  width: 100%;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button,
  ::-webkit-calendar-picker-indicator {
    opacity: 0;
    -webkit-appearance: none;
  }

  ${props => props.type === "date" && css`
    background: url(https://i.wlycdn.com/wealthy-kyc/kyc-calendar-icon.svg) no-repeat;
    background-position: right;
    width: 100%;
    border-width: thin;
  `}
`;

const InputsContainer = styled.div`
  margin: 7rem 0 0 0;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem;
  border-radius: 1.2rem;
  background: #ffffff;
  margin-bottom: 1.6rem;
`;

const CheckBox = styled.input`
  margin-right: 1.5rem;
`;

const CheckboxText = styled.p`
  color: #7E7E7E;
  font-size: 1.4rem;
`;

export default PersonalDetails;