
/**
Author - Brijesh Pandey
Git - https://github.com/Brijesh-Pandey
**/

const Nominee = {
  title: "Nominee Information",
  subtext: "At Wealthy, we understand that life is constantly evolving, and so are your preferences and decisions. That's why we've made the Nominee step completely skippable during your KYC process. We want to ensure that you have the flexibility and control to make updates whenever you're ready, without any hassle.",
  description: "What is a nominee ? A nominee is someone you trust to manage your assets and investments in case of unforeseen circumstances. While it's an important consideration, we recognize that you might not have all the details right now. Rest assured, you can easily add this information at a later time through our user-friendly app or web platform."
};

export {
  Nominee
};