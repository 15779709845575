/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/



import {
toast
} from "react-toastify";

const triggerToast = (message, type = "error", properties = {}) => {
  toast(message, {
    autoClose: 5000,
    closeButton: true,
    type,
    pauseOnHover: true,
    ...properties
  });
};

export default triggerToast;
