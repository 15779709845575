/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React, {
  useState, useEffect
} from "react";

import {
  updatePennyDrop
} from "api";
import {
  handleApiError,
  formValidator
} from "helpers";
import {
  useCheckIfscCode
} from "hooks";

import BankDetails from "./BankDetails";
import {
  toast
} from 'react-toastify';

const formFields = ["beneficiaryAccountIfsc", "beneficiaryAccountNo"];

const ACCOUNT_TYPES_MSG = {
  "INDIVIDUAL_NRE":"Please enter your <mark> NRE  account </mark> details only",
  "INDIVIDUAL_NRO":"Please enter your <mark> NRO  account </mark> details only"
};

const BankDetailsContainer = ({
  kycData,
  updateStatus
}) => {
  const [formData, setFormData] = useState({
    "beneficiaryAccountIfsc": kycData?.prefill?.beneficiaryAccountIfsc,
    "beneficiaryAccountNo": kycData?.prefill?.beneficiaryAccountNo,
  });
  
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  // const [isAccountNumberConfirmed, setAccountNumberConfirmed] = useState(null);

  const [isIfscValid, ifscResponse] = useCheckIfscCode(formData.beneficiaryAccountIfsc, setIsLoading);
  const accountTypeText = ACCOUNT_TYPES_MSG[kycData.accountUsageType];

  useEffect(() => {
    if (formData.beneficiaryAccountIfsc?.length === 11 && isIfscValid) {
      setIsDisabled(formValidator(formFields, formData));
    } else {
      setIsDisabled(true);
    }
  }, [formData, isIfscValid]);

  // const confirmAccountNumber = () => {
  //   if (formData.beneficiaryAccountNo?.toString() !== formData.confirmAccountNo?.toString()) {
  //     setAccountNumberConfirmed(false);
  //     setIsDisabled(true);
  //   } else {
  //     setAccountNumberConfirmed(true);
  //     if (formData.beneficiaryAccountIfsc?.length === 11) {
  //       setIsDisabled(false);
  //     }
  //   }
  // }; 

  const onChangeHandler = (event) => {
    const {
      name,
      value
    } = event.target;
    if (name === "beneficiaryAccountIfsc" && value.length > 11) {
      return;
    }
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const payload = {
        ...formData
      };
      const res = await updatePennyDrop(payload);
      if (res.status === 200) {
        updateStatus();
      }
    } catch (error) {
      if(error?.response?.data?.detail?.internalCode === "reload") {
          handleApiError(error, toast.TYPE.WARNING, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
            icon: "⏭️"
          });
          setTimeout(updateStatus, 4000);
      }
      else {
        handleApiError(error);
      }
      
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BankDetails
      // confirmAccountNumber={confirmAccountNumber}
      formData={formData}
      ifscResponse={ifscResponse}
      // isAccountNumberConfirmed={isAccountNumberConfirmed}
      isIfscValid={isIfscValid}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onChangeHandler={onChangeHandler}
      onSubmit={onSubmit}
      accountTypeText={accountTypeText}
    />
  );

};

export default BankDetailsContainer;
