/**
Author - Brijesh Pandey
Git - https://github.com/Brijesh-Pandey
**/

import React from "react";

import styled from "styled-components";

import PropTypes from "prop-types";

const downArrow = "https://i.wlycdn.com/wealthy-home-page/partners-dropdown-arrow.svg";

const propTypes = {
  dropdownRef: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  setShowDropdown: PropTypes.func.isRequired,
  showDropdown: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
};

const Dropdown = ({
  showDropdown,
  setShowDropdown,
  options,
  title,
  dropdownRef
}) => (
    <DropdownContainer ref={dropdownRef} onClick={() => setShowDropdown(!showDropdown)}>
      <TitleContainer>{title}</TitleContainer>
      <DropdownImage>
        <img
          alt="dropdown-icon"
          loading="lazy"
          src={downArrow}
        />
      </DropdownImage>
      {
        showDropdown && (
          <ul className="dropdown-menu">
            {options}
          </ul>
        )
      }
    </DropdownContainer>
  );

const DropdownImage = styled.div`
  width: 2.4rem;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 1.8rem;
    >img {
      width: 100%;
      vertical-align: middle;
    }
  }
`;

const TitleContainer = styled.div.attrs({
  className: 'form-input'
})`
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #6c6c6c;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  padding: 1rem 2.4rem;
  width: 100%;
  margin: 0 auto 3rem;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  height: 5rem;
  /* box-shadow: 4px 4px 20px rgb(0 0 0 / 15%); */
  color: #6C6C6C;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  position: relative;
  z-index: 2;
  border: 1px solid #6725f4;

  .dropdown-menu {
    display: block;
    border-radius: 0.6rem;
    max-height: 450px;
    background: #fff;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    position: absolute;
    border: 1px solid #6725f4;
    border-top: 0;
    width: calc(100% - 2px);
    left: 0;
    top: calc(100% - 2px);
    z-index: 2;
  }
  .dropdown-menu .dropdown-menu__item {
    font-size: 1.6rem;
    color: #6c6c6c;
    text-align: center;
    border-radius: 0;
    padding: 0.2rem 0;
  }

  :hover {
    cursor: pointer;
    box-shadow: 4px 4px 20px rgb(0 0 0 / 25%);
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 4.5rem;

    .dropdown-menu::after {
      display: none !important;
    }
  }

  @media (max-height: 660px) {
    height: 4.5rem;
  }

  @media (max-height: 600px) {
    height: 4.5rem;
  }
`;

Dropdown.propTypes = propTypes;

export default Dropdown;
