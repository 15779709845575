/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React from "react";

import styled from "styled-components";

import NomineeForm from "./components";

const BankDetails = ({
  onChangeHandler,
  checkIsMinor,
  onSubmit,
  formData,
  isLoading,
  isDisabled,
  isMinor,
  nomineeCount,
  nomineeCountChangeHandler,
  relationDropdownContent,
  relationDescription,
  setRelationDescription
}) => (
    <NomineeDetailsWrapper>
      {
        <NomineeForm
          checkIsMinor={checkIsMinor}
          formData={formData}
          isLoading={isLoading}
          isDisabled={isDisabled}
          isMinor={isMinor}
          nomineeCount={nomineeCount}
          onChangeHandler={onChangeHandler}
          onSubmit={onSubmit}
          nomineeCountChangeHandler={nomineeCountChangeHandler}
          relationDropdownContent={relationDropdownContent}
          relationDescription={relationDescription}
          setRelationDescription={setRelationDescription}
        />
      }
    </NomineeDetailsWrapper>
  );

const NomineeDetailsWrapper = styled.div`
  /* background: #FFF; */
  height: 100vh;
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;

  @media (max-width: 768px) {
    background:#fff;
  }
`;


export default BankDetails;
