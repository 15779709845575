/**
Author - Brijesh Pandey
Git - https://github.com/Brijesh-Pandey
**/

import React, {
  useState
} from "react";
import {
  uploadPassport
} from "api";
import {
  handleApiError
} from "helpers";
import {
  PropTypes
} from "prop-types";


import Passport from "./Passport";

const propTypes = {
  updateStatus: PropTypes.func.isRequired,
};

const PassportContainer = ({updateStatus}) => {
  const [isUploading, setIsUploading] = useState(false);


  const handleSubmitFile = async (formData) => {
    setIsUploading(true);
    try {
      const res = await uploadPassport(formData);
      if (res.status === 200) {
        updateStatus();
      }
      if (!res.ok) {
        throw {
          response: {
            status: 400,
            data: {
              detail: {
                message: "File Format Not Supported"
              }
            }
          }
        };
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsUploading(false);
    }

  };

  return (
    <Passport
      handleSubmitFile={handleSubmitFile}
      isUploading={isUploading}
    />
  );

};

PassportContainer.prototype = propTypes;

export default PassportContainer;