import {
  createGlobalStyle
} from "styled-components";

export const OpsEvaluationStyles = createGlobalStyle`{
    .ops-evaluation-component-wrapper{
        width: 100vw !important;
        height:100vh !important;
        margin: 0 !important;
        padding: 0 !important;
        border-radius: 0 !important;
        box-shadow: none !important;
    }
}`;