/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import React, {
  useState,
  useEffect
} from "react";
import PropTypes from "prop-types";

import {
  selectFlowType,
  checkPanAadharSeeded
} from "api";
import {
  FlowType
} from "enums";
import {
  handleApiError
} from "helpers";
import {
  RudderStackEvent
} from "types";
import {
  Analytics,
} from "utils";

import CheckDigiFlow from "./CheckDigiFlow";

const propTypes = {
  updateStatus: PropTypes.func
};

const CheckDigiFlowContainer = ({
  updateStatus
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPanAadharSeeded, setIsPanAadharSeeded] = useState(false);
  const [isFetchingPanAadharSeeded, setFetchingPanAadharSeeded] = useState(false);

  useEffect(() => {
    checkIsPanAadharSeeded();
  }, []);

  const checkIsPanAadharSeeded = async () => {
    try {
      setFetchingPanAadharSeeded(true);
      const {data} = await checkPanAadharSeeded();
      Analytics.identifyForRudder(data.response?.userId);

      setTimeout(() => {
        Analytics.trackForRudder(RudderStackEvent.KYC_CHOICE);
      }, 1000);

      if (data.response?.isPanLinked) {
        setIsPanAadharSeeded(true);
      } else {
        setIsPanAadharSeeded(false);
        await handleFormSubmit(FlowType.Manual);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setFetchingPanAadharSeeded(false);
    }
  };

  const handleFormSubmit = async (value) => {
    try {
      setIsLoading(true);
      if (value === FlowType.Digilocker) {
        Analytics.trackForRudder(RudderStackEvent.KYC_DIGILOCKER);
      } else {
        Analytics.trackForRudder(RudderStackEvent.KYC_MANUAL);
      }
      const res = await selectFlowType({"selection": value});
      if (res.status === 200) {
        updateStatus();
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    };
  };

  return (
    <CheckDigiFlow
      handleFormSubmit={handleFormSubmit}
      isLoading={isLoading}
      isPanAadharSeeded={isPanAadharSeeded}
      isFetchingPanAadharSeeded={isFetchingPanAadharSeeded}
    />
  );
};

CheckDigiFlowContainer.propTypes = propTypes;

export default CheckDigiFlowContainer;
