/* eslint-disable object-curly-newline */

/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  ActionButton,
  Spinner
} from "screens/commons";

const propTypes = {
  kycData: PropTypes.object.isRequired,
  handleSubFlow: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  timer: PropTypes.number.isRequired,
  isSubflowPresent: PropTypes.bool.isRequired,
  handleRedirection: PropTypes.func.isRequired,
  skippingSuccessScreen: PropTypes.bool.isRequired,
  isRedirectLoading: PropTypes.bool.isRequired
};

const PartnerKycSuccessScreen = ({
  handleSubFlow,
  kycData,
  isRedirectLoading,
  timer,
  isSubflowPresent,
  handleRedirection,
  skippingSuccessScreen
}) => {
  const successScreenImage = "https://i.wlycdn.com/wealthy-kyc/kyc-success-tick.svg";
  const defaultData = {
    title: "KYC Application Submitted Successfully",
    content: "",
    ctaText: "Continue to App",
    link:"",
  };

  let subHeaderText = "You can click on the button below to go back to the app or you will be redirected automatically in";

  const showSkipButton = isSubflowPresent && kycData.flow === 'PARTNER';

  const isSuccessContentPresent = !!kycData.successContent?.title;
  const title = isSuccessContentPresent ? kycData.successContent?.title : defaultData.title;
  const message = isSuccessContentPresent ? kycData.successContent?.msg : defaultData.msg;
  let ctaText = isSuccessContentPresent ? kycData.successContent?.cta : defaultData.ctaText;
  const link = isSuccessContentPresent ? kycData.successContent?.link : defaultData.link;


  // Explicit condition checks
  const isFlowPartnerGST = kycData?.flow === "PARTNER_GST";

  if (isSubflowPresent) {
    ctaText = kycData.subFlow?.ctaName ? kycData.subFlow.ctaName : defaultData.ctaText;
  } else if (isFlowPartnerGST) {
    subHeaderText = "To continue with the empanelment process, please click the button below or you will be redirected automatically in ";
  }

  return (
    isRedirectLoading || skippingSuccessScreen ? (
      <LoaderContainer>
        <Spinner
          color="blue"
          message="Please do not close the window, wait while we are fetching next steps."
        />
      </LoaderContainer>
    ) : (
      <SuccessScreenWrapper>
        <Container>
          {
            showSkipButton ? (
              <Header>
                <Caption onClick={handleRedirection}>Skip</Caption>
              </Header>
            ) : <></>
          }
          <SuccessContent showSkipButton={showSkipButton}>
            <ImageContainer>
              <img alt="connect-with-wealth-partner" src={successScreenImage} />
            </ImageContainer>
            <Title>{title}</Title>
            <SubHeader dangerouslySetInnerHTML={{__html: message}}/>
            <SubHeader dangerouslySetInnerHTML={{__html: link}}/>
            {
              !isSubflowPresent ? (
                <SubHeader>
                 {subHeaderText} {timer} seconds
                </SubHeader>
              ) : (
                <SubHeader>
                  {message}
                </SubHeader>
              )
            }
          </SuccessContent>
          <Footer>
            <ActionButton
              buttonText={ctaText}
              callback={handleSubFlow}
              isDisabled={isRedirectLoading}
            />
          </Footer>
        </Container>
      </SuccessScreenWrapper>
    )
  );
};

const SuccessScreenWrapper = styled.div`
  height: 100vh;
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;
`;


const LoaderContainer = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: 768px) {
    width: 85%;
    margin: 0 auto;
  }
`;

const Container = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 90%
`;

const SuccessContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${props => props.showSkipButton ? '0rem' : '12rem'};
`;

const ImageContainer = styled.div``;

const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  white-space: pre-wrap;
  margin: 0;
  padding: 0;
  margin-top: 3.6rem;
  line-height: 1.4;
  letter-spacing: 0.1rem;
  text-align: center;
`;

const Caption = styled.span.attrs({
  className: 'caption'
})`
  margin: 0;
  padding: 0;
  color: #6725F4;
  letter-spacing: 0.1rem;
  align-self: end;
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  flex-shrink: 1;
`;

const Footer = styled.div`
  width: 100%;
`;

const SubHeader = styled.p.attrs({
  className: 'description2'
})`
  margin: 1rem 0 0 0;
  padding: 0;
  font-size: 1.6rem !important;
  text-align: center;
  color: #7E7E7E;
  margin-top: 1.6rem;
  max-width: 90%;
  :not(:first-child) {
    margin-top: 3rem;
  }
`;

PartnerKycSuccessScreen.propTypes = propTypes;

export default PartnerKycSuccessScreen;