/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import React, {
  useState,
  useEffect
} from "react";

import {
uploadDocuments
} from "api";

import UploadDocument from "./UploadDocument";
import {
  handleApiError
} from "helpers";

const UploadDocumentContainer = ({
  declarationsList,
  updateStatus
}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showAnimation, setShowAnimation] = useState(false);
  const [binaryImage, setBinaryImage] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const [password, setPassword] = useState("");

  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    if (binaryImage?.name?.length > 0 && selectedOption?.key?.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selectedOption, binaryImage]);

  const startAnimation = () => {
    setShowAnimation(true);
    setTimeout(() => {
      setShowAnimation(false);
    }, 1200);
  };

  const handleDeleteUploadedFile = (file) => {
    const filteredList = uploadedFiles.filter((eachFile) => eachFile.id !== file.id);
    setUploadedFiles(filteredList);
    setBinaryImage(null);
    setSelectedOption("");
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      if (binaryImage !== null) {
        const formData = new FormData();
        formData.append("doc_file", binaryImage, binaryImage.name);
        if (password && password.length > 0) {
          formData.append("doc_pass", password);
        }
        const res = await uploadDocuments(formData, selectedOption?.key);
        if (res.status === 200) {
          updateStatus();
        }
        if (!res.ok) {
          throw {
            response: {
              status: 400,
              data: {
                detail: {
                  message: "File Format Not Supported"
                }
              }
            }
          };
        }
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageUpload = (files) => {
    const uploadedFiles = [];

    files.forEach((eachFile, index) => {
      eachFile.id = index;
      uploadedFiles.push(eachFile);
    });

    let isLimitExceeded = false;

    if (!isLimitExceeded) {
      const fileImage = files[0];
      setBinaryImage(fileImage);

      setUploadedFiles(uploadedFiles);
      startAnimation();
    }
  };

  return (
    <UploadDocument
      declarationsList={declarationsList}
      handleImageUpload={handleImageUpload}
      handleDeleteUploadedFile={handleDeleteUploadedFile}
      isDisabled={isDisabled}
      isLoading={isLoading}
      uploadedFiles={uploadedFiles}
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
      showAnimation={showAnimation}
      onSubmit={onSubmit}
      setPassword={setPassword}
      password={password}
    />
  );
};

export default UploadDocumentContainer;
