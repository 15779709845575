/**
Author - Brijesh Pandey
Git - https://github.com/Brijesh-Pandey
**/

import React from "react";
import styled from "styled-components";
import parse from 'html-react-parser';

import {
  ActionButton,
  Spinner,
  Error
} from "../../../commons";

const DeclarationList = ({
  isLoading,
  isError,
  declarationsList,
  handleContinueClick,
  skipBrokingFlow
}) => {
  const content = declarationsList.allowedIncomeDeclarations?.map((eachDeclaration) => (
    <ContentWrapper>
      <BulletImage
        alt="bullet-image"
        src="https://i.wlycdn.com/wealthy-home-page/bullet-star.svg"
        loading="lazy"
      />
      <DeclarationContent>{parse(eachDeclaration.render)}</DeclarationContent>
    </ContentWrapper>
  ));

  return (
    <>
      {
        isError ? (
          <ErrorContainer>
            <Error />
          </ErrorContainer>
        ) : (
          isLoading ? (
            <LoaderContainer>
              <Spinner
                color="blue"
                message="Fetching Details"
              />
            </LoaderContainer>
          ) : (
            <DeclarationsWrapper>
              <Container>
                <Title>{declarationsList.pageTitle}</Title>
                <ContentContainer>
                  <CardTitle>
                  {declarationsList.cardTitle}
                  </CardTitle>
                  {content}
                </ContentContainer>
                <ActionButton
                  buttonText="Continue"
                  callback={handleContinueClick}
                />
                <SkipButton
                  onClick={skipBrokingFlow}
                >
                  Skip for now
                </SkipButton>
                <ExtraSpace />
              </Container>
            </DeclarationsWrapper>
          )
        )
      }
    </>
  );
};

const LoaderContainer = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentContainer = styled.div`
  margin: 7rem 0 0 0;
  padding: 2rem 1rem;
  background: #fff;

  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const ErrorContainer = styled.div`
  background: #ffffff;
  height: 100vh;
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;
  padding: 2.4rem;
  text-align: center;

  .error-image {
    width: 60%;
    margin-bottom: 5rem;
  }

  @media (max-width: 768px) {
    background:#fff;
    .error-image {
      width: 90%;
    }
  }
`;

const DeclarationsWrapper = styled.div`
  height: 100vh;
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;

  @media (max-width: 768px) {
    background:#fff;
  }
`;

const ExtraSpace = styled.div`
  height: 250px;
  margin: 10rem 0;
  opacity: 0;
`;

const Container = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  margin: 0 auto;
`;

const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  letter-spacing: 0.1rem;
`;

const ContentWrapper = styled.div`
  display: flex;
`;

const SkipButton = styled.button.attrs({
  className: 'button-title'
})`
  background: transparent;
  color: #6725F4;
  border-radius: 52px !important;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background: transparent;
  }
`;

const CardTitle = styled.p`
  font-family: MavenPro;
  font-size: 1.6rem;
  text-align: left;
  font-weight: 500;
  color: #000000;
  line-height: 1.2;
`;

const BulletImage = styled.img`
  margin-right: 1rem;
  height: 100%;
  margin-top: 3.1rem;
`;

const DeclarationContent = styled.p.attrs({
  className: 'description2'
})`
  margin: 2.4rem 0 0 0;
  padding: 0;
  color: #7E7E7E;
`;

export default DeclarationList;
