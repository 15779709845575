/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React from "react";

import styled from "styled-components";

import {
  ActionButton,
  BackButton
} from "screens/commons";

const BasicDetails = ({
  onChangeHandler,
  onSubmit
}) => (
    <BasicDetailsWrapper>
      <Container>
        <BackButton />
        <Title>Basic Details</Title>
        <SubHeader>Confirm your mobile number and email id</SubHeader>
        <InputsContainer>
          <InputContainer>
            <Label>Mobile Number</Label>
            <Input />
          </InputContainer>

          <InputContainer>
            <Label>Email Id</Label>
            <Input />
          </InputContainer>

          <ActionButton
            buttonText="Confirm"
            callback={onSubmit}
            additionalStyles={{marginTop: "15rem"}}
          />

        </InputsContainer>
      </Container>
    </BasicDetailsWrapper>
  );

const BasicDetailsWrapper = styled.div`
  height: 100vh;
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;

  @media (max-width: 768px) {
    background: white;
  }
`;

const Container = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  margin: 0 auto;
`;

const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  letter-spacing: 0.1rem;
`;

const SubHeader = styled.p.attrs({
  className: 'description2'
})`
  margin: 1rem 0 0 0;
  padding: 0;
  color: #7E7E7E;
`;

const InputContainer = styled.div`
  margin-bottom: 3.3rem;
`;

const Label = styled.p.attrs({
  className: 'description2'
})`
  color: #7E7E7E;
`;

const Input = styled.input.attrs({
  className:'form-input'
})`
  border: none;
  background: transparent;
  border-bottom: 1px solid #DDDDDD;
  outline: none;
  padding-bottom: 0.5rem;
  color: #000000;
  width: 100%;
`;

const InputsContainer = styled.div`
  margin: 7rem 0 0 0;
`;

export default BasicDetails;