/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/



import Cookies from "js-cookie";

import {
ACCESS_TOKEN_COOKIE_KEY
} from "constants/cookie";

const isAuthenticated = () => !!Cookies.get(ACCESS_TOKEN_COOKIE_KEY);

export default isAuthenticated;
