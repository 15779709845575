/**
Author - Venkatesh Pullaganti
Git - https://github.com/venkatesh1146
**/

import React, {
  useState,
  useEffect
} from "react";

import {
PropTypes
} from "prop-types";

import UploadDocument from "./UploadDocument";


const propTypes = {
  onSubmitDocument:PropTypes.func.isRequired,
  isLoading:PropTypes.bool.isRequired,
  heading:PropTypes.string.isRequired,
  subHeading:PropTypes.string.isRequired,
  documentOptions:PropTypes.array,
  errorMessage:PropTypes.string,
};

const UploadDocumentContainer = ({
  onSubmitDocument,
  isLoading,
  heading,
  subHeading,
  documentOptions,
  errorMessage 
}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showAnimation, setShowAnimation] = useState(false);
  const [binaryImage, setBinaryImage] = useState(null);

  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedOption, setSelectedOption] = useState(documentOptions.length ? documentOptions[0] : null);
  const [password, setPassword] = useState(null);

  useEffect(() => {
    if(documentOptions.length){
      setSelectedOption(documentOptions[0]);
    }    
  }, []);

  useEffect(() => {
    if (binaryImage?.name?.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [binaryImage]);

  const startAnimation = () => {
    setShowAnimation(true);
    setTimeout(() => {
      setShowAnimation(false);
    }, 1200);
  };

  const handleDeleteUploadedFile = (file) => {
    const filteredList = uploadedFiles.filter((eachFile) => eachFile.id !== file.id);
    setUploadedFiles(filteredList);
    setBinaryImage(null);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (binaryImage !== null) {
      const formData = new FormData();
      formData.append("doc_file", binaryImage, binaryImage.name);
      if(password && password.length){
        formData.append("doc_pass", password);
      }
      onSubmitDocument(formData,selectedOption?.key);
    }
  };

  const handleImageUpload = (files) => {
    const uploadedFiles = [];

    files.forEach((eachFile, index) => {
      eachFile.id = index;
      uploadedFiles.push(eachFile);
    });

    let isLimitExceeded = false;

    if (!isLimitExceeded) {
      const fileImage = files[0];
      setBinaryImage(fileImage);

      setUploadedFiles(uploadedFiles);
      startAnimation();
    }
  };

  return (
    <UploadDocument
      handleImageUpload={handleImageUpload}
      handleDeleteUploadedFile={handleDeleteUploadedFile}
      isDisabled={isDisabled}
      isLoading={isLoading}
      uploadedFiles={uploadedFiles}
      showAnimation={showAnimation}
      onSubmit={onSubmit}
      heading={heading}
      subHeading={subHeading}
      documentOptions={documentOptions}
      allowPasswordProtected
      setSelectedOption={setSelectedOption}
      selectedOption={selectedOption}
      password={password}
      setPassword={setPassword}
      errorMessage={errorMessage}
    />
  );
};

UploadDocumentContainer.prototype = propTypes;

export default UploadDocumentContainer;
