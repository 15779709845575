/**
Author - Venkatesh Pullaganti
Git - https://github.com/venkatesh1146
**/

import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import parse from 'html-react-parser';


import {
  ActionButton,
  Dropdown
} from "screens/commons";

import {
  AnimationText,
  Animation,
  AttentionContainer,
  ActionWrapper,
  DeleteWrapper,
  DeleteIcon,
  DetailWrapper,
  ExtraSpace,
  Text,
  ImageSize,
  Wrapper,
  Title,
  ImageCard,
  PreviewImage,
  SubHeader,
  ImagesContainer,
  Container,
  UploadImage,
  UploadMessage,
  DropZoneContainer,
  EachOption,
  PasswordInput,
  DropContainer
} from "./UploadTwoSidedDoc.styles";

const propTypes = {
  handleImageUpload: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array.isRequired,
  showAnimation: PropTypes.bool.isRequired,
  handleDeleteUploadedFile: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  documentOptions: PropTypes.array,
  allowPasswordProtected: PropTypes.bool,
  setSelectedOption: PropTypes.func.isRequired,
  selectedOption: PropTypes.func.isRequired,
  password: PropTypes.string,
  setPassword: PropTypes.string
};

const UploadTwoSidedDocument = ({
  declarationsList,
  isLoading,
  isDisabled,
  handleImageUpload,
  uploadedFiles,
  showAnimation,
  handleDeleteUploadedFile,
  onSubmit,
  heading,
  subHeading,
  documentOptions = [],
  allowPasswordProtected,
  setSelectedOption,
  selectedOption,
  password,
  setPassword
}) => {
  const fileUploadImage = "https://i.wlycdn.com/wealthy-home-page/upload-cloud-icon.png";
  const deleteImage = "https://i.wlycdn.com/wealthy-home-page/partner-close-button.svg";

  const dropdownOptions = documentOptions.length ? documentOptions?.map((eachDeclaration) => (
    <>
      <EachOption
        onClick={() => setSelectedOption(eachDeclaration)}
      >
        {parse(eachDeclaration?.render)}
      </EachOption>
    </>
  )) : [];

  const CommonInstructions = () => (<>
    <br />
    ● Supported file formats include only png, jpg, jpeg and pdf
    <br />
    ● If both sides of the document are in single file please upload it on both front and back sides
  </>);

  return (
    <Wrapper>
      {heading && <Title>{heading}</Title>}
      {subHeading && <SubHeader dangerouslySetInnerHTML={{__html: subHeading}} />}
      {documentOptions.length ? <>
        <ExtraSpace />
        <Dropdown
          options={dropdownOptions}
          title={selectedOption?.render ? parse(selectedOption?.render) : "Choose an option"}
        />
      </>
        : null}

      <DropContainer>
        <DropZoneContainer>
          <Dropzone
            onDrop={(file) => handleImageUpload(file, 'front_side')}
            multiple={false}
            accept={{
              "image/png": [".png"],
              "image/jpeg": [".jpg", ".jpeg"],
              "application/pdf": [".pdf"]
            }}
          >
            {({getRootProps, getInputProps}) => (
              <section>
                <div {...getRootProps()}>
                  <Container>
                    <UploadImage src={fileUploadImage} />
                  </Container>
                  <input {...getInputProps()} />
                  <UploadMessage>  <b> Front side </b> : Drag 'n' drop the document here or click to select files</UploadMessage>
                </div>
              </section>
            )}
          </Dropzone>
        </DropZoneContainer>
        <DropZoneContainer>
          <Dropzone
            onDrop={(file) => handleImageUpload(file, 'back_side')}
            multiple={false}
            accept={{
              "image/png": [".png"],
              "image/jpeg": [".jpg", ".jpeg"],
              "application/pdf": [".pdf"]
            }}
          >
            {({getRootProps, getInputProps}) => (
              <section>
                <div {...getRootProps()}>
                  <Container>
                    <UploadImage src={fileUploadImage} />
                  </Container>
                  <input {...getInputProps()} />
                  <UploadMessage> <b> Back side </b>: Drag 'n' drop the document here or click to select files</UploadMessage>
                </div>
              </section>
            )}
          </Dropzone>
        </DropZoneContainer>
      </DropContainer>

      <ImagesContainer>
        {
          uploadedFiles.map((eachFile) => (
            showAnimation ? (
              <Animation>
                <AnimationText>Uploading...</AnimationText>
              </Animation>
            ) : (
              <>
                <ImageCard>
                  <PreviewImage src={URL.createObjectURL(eachFile)} />
                  <DetailWrapper>
                    <Text>{eachFile.name}</Text>
                    <ImageSize>{eachFile.size * 0.001}Kb</ImageSize>
                  </DetailWrapper>
                  <ActionWrapper>
                    <DeleteWrapper onClick={() => handleDeleteUploadedFile(eachFile)}>
                      <DeleteIcon
                        alt="delete image"
                        src={deleteImage}
                      />
                    </DeleteWrapper>
                  </ActionWrapper>
                </ImageCard>
              </>
            )
          ))
        }
      </ImagesContainer>

      {allowPasswordProtected ? <>
        <AttentionContainer><span>Attention:</span>
          <br />
          ● Please provide passwords in the below inputs if they are password-protected documents.
          <CommonInstructions />
        </AttentionContainer>
        <br />
        <AttentionContainer>Front side document password:</AttentionContainer>
        <PasswordInput
          placeholder="Type your front side doc password here..."
          type="password"
          value={password.frontSide}
          onChange={(event) => setPassword({
            ...password,
            frontSide: event.target.value
          })}
        />
        <AttentionContainer>Back side document password:</AttentionContainer>
        <PasswordInput
          placeholder="Type your back side doc password here..."
          type="password"
          value={password.backSide}
          onChange={(event) => setPassword({
            ...password,
            backSide: event.target.value
          })}
        />
      </> :
        <AttentionContainer><span>Attention:</span>
          <br />
          ● Please do not upload password-protected documents.
          <CommonInstructions />
        </AttentionContainer>
      }
      <ActionButton
        buttonText="Upload & Continue"
        callback={onSubmit}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
    </Wrapper>
  );
};

UploadTwoSidedDocument.propTypes = propTypes;

export default UploadTwoSidedDocument;
