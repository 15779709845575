/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import styled from "styled-components";

const PartnerBankDetailsWrapper = styled.div`
  height: 100vh;
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;
`;

const ExtraSpace = styled.div`
  height: 250px;
  margin: 10rem 0;
  opacity: 0;
`;

const Container = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  margin: 0 auto;
  height: 80%;
`;

const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  letter-spacing: 0.1rem;
`;

const InputsContainer = styled.div`
  margin: 7rem 0 0 0;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
`;

const SubHeader = styled.span.attrs({
  className: 'caption'
})`
  margin: 0;
  padding: 0;
  color: #6725F4;
  letter-spacing: 0.1rem;
  align-self: end;
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: start;
`;

const LoaderContainer = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  PartnerBankDetailsWrapper,
  ExtraSpace,
  Container,
  InputsContainer,
  Title,
  SubHeader,
  Header,
  LoaderContainer
};
