/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import triggerToast from "./triggerToast";

const handleApiError = (err, toastType = "default", options = {}) => {
  if (err && err.response &&
    err.response.status) {
    let errorCode = err.response.status;
    errorCode = parseInt(errorCode);

    if (err.response?.data) {
      if (Array.isArray(err.response.data?.detail)) {
        triggerToast(err.response.data?.detail[0]?.msg);
      } else if (typeof err.response?.data?.detail === 'object') {
        triggerToast(err.response?.data?.detail?.message, toastType, options);
      } else {
        triggerToast(err.response?.data?.detail);
      }
    } else {
      switch (errorCode) {
        case 400:
          triggerToast("Token expired, please re-initiate your KYC");
          break;
        case 500:
          triggerToast("CVL is down, please try again after sometime");
          break;
        case 401:
          triggerToast("Credentials mismatch, please re-initiate your KYC");
          break;
        case 422:
          triggerToast("You are not a validated user, please re-initiate your KYC");
          break;
        case 502:
          triggerToast("Server timeout, please try again after sometime");
          break;
        default:
          triggerToast("Something went wrong. Please try again");
      }
    }
  } else {
    triggerToast("Something went wrong. Please try again");
  }
};

export default handleApiError;
