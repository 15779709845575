import React, {
 useEffect, useState
} from "react";
import RadioButtonCard from "./RadioButtonCard";
import styled from "styled-components";
import {
 ActionButton
} from "screens/commons";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
`;

const Title = styled.h1.attrs({
  className: "primary-title",
})`
  margin: 0;
  padding: 0;
  color: #000000;
  letter-spacing: 0.1rem;
`;

const SubHeader = styled.p.attrs({
  className: "description2",
})`
  margin: 1rem 0 0 0;
  padding: 0;
  color: #7e7e7e;
`;

const ChoiceComponent = ({apiResponse, handleSubmit}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [disableButton, setDisableButton] = useState(true);

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  useEffect(() => {
    if (selectedOption) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [selectedOption]);

  const options = apiResponse?.schema?.fields?.[0]?.meta?.options || [];

  return (
    <div>
      <Title dangerouslySetInnerHTML={{__html: apiResponse?.pageTitle}} />
      <SubHeader>{apiResponse?.cardTitle}</SubHeader>
      <Container>
        {options?.map((option) => (
          <RadioButtonCard
            key={option?.value}
            label={option?.label}
            subLabel={option?.subLabel}
            instruction={option?.instruction}
            value={option?.value}
            checked={selectedOption === option?.value}
            onClick={() => handleOptionChange(option?.value)}
          />
        ))}
        <ActionButton
            buttonText="Continue"
            callback={disableButton ? null : () => handleSubmit(selectedOption)}
            isLoading={false}
            isDisabled={disableButton}
        />
      </Container>
    </div>
  );
};

export default ChoiceComponent;
