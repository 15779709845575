/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/



const ACCESS_TOKEN_COOKIE_KEY = "wlag_xxid_kyc";
const AGENT_INFO_COOKIE_KEY = "agentInfo";
const PARTNER_INFO_COOKIE_KEY = "partner";
const APP_VERSION_COOKIE_KEY = "app_version";
const SOURCE_KEY = 'source';

export {
  ACCESS_TOKEN_COOKIE_KEY,
  AGENT_INFO_COOKIE_KEY,
  PARTNER_INFO_COOKIE_KEY,
  APP_VERSION_COOKIE_KEY,
  SOURCE_KEY
};
