/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React from "react";
import PropTypes from "prop-types";
import errorIllustration from "./assets/error-illustration.svg";
import {
  ActionButton
} from "../index";

const propTypes = {
  children: PropTypes.node,
  imageUrl: PropTypes.string,
  message: PropTypes.string
};

const defaultProps = {
  imageUrl: errorIllustration,
  message: "Oops! Something went wrong, Please close the window and try again after sometime."
};

const Error = ({
  children,
  imageUrl,
  message
}) => (
    <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center mb-5">
      <div>
        <img
          alt="ERROR"
          className="error-image"
          src={imageUrl}
        />
      </div>
      <div className="d-flex flex-column align-items-center text-grey">
        <p className="mt-5 mb-2 lead">{message}</p>
      </div>
      {children ? children :
      <ActionButton
          buttonText={"Refresh"}
          callback={() => { window?.location?.reload(); }}
          isDisabled={false}
          additionalStyles={{maxWidth:"600px"}}
        />}
    </div>
  );

Error.propTypes = propTypes;
Error.defaultProps = defaultProps;

export default Error;
