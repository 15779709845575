/**
Author - Venkatesh Pullaganti
Git - https://github.com/venkatesh1146
**/

import React, {
  useState
} from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import parse from 'html-react-parser';


import {
  ActionButton,
  Dropdown
} from "screens/commons";

import {
  AnimationText,
  Animation,
  AttentionContainer,
  ActionWrapper,
  DeleteWrapper,
  DeleteIcon,
  DetailWrapper,
  ExtraSpace,
  Text,
  ImageSize,
  Wrapper,
  Title,
  ImageCard,
  PreviewImage,
  SubHeader,
  ImagesContainer,
  Container,
  UploadImage,
  UploadMessage,
  DropZoneContainer,
  EachOption,
  PasswordInput,
  OptionInfoText,
  InfoTextImg,
  InfoTextAndImg,
  ErrorMessage
} from "./UploadDocument.styles";
import IMAGES from "constants/Images";

const propTypes = {
  handleImageUpload: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array.isRequired,
  showAnimation: PropTypes.bool.isRequired,
  handleDeleteUploadedFile: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  documentOptions: PropTypes.array,
  allowPasswordProtected: PropTypes.bool,
  setSelectedOption: PropTypes.func.isRequired,
  selectedOption: PropTypes.func.isRequired,
  password: PropTypes.string,
  setPassword: PropTypes.string,
  uploadMessage: PropTypes.string
};

const UploadDocument = ({
  declarationsList,
  isLoading,
  isDisabled,
  handleImageUpload,
  uploadedFiles,
  showAnimation,
  handleDeleteUploadedFile,
  onSubmit,
  heading,
  subHeading,
  documentOptions = [],
  allowPasswordProtected,
  setSelectedOption,
  selectedOption,
  password,
  setPassword,
  errorMessage = "",
  uploadMessage = "Drag 'n' drop the chosen document here or click to select files"
}) => {
  const [selectedOptionInfo, setSelectedOptionInfo] = useState(selectedOption?.text || "");
  const fileUploadImage = "https://i.wlycdn.com/wealthy-home-page/upload-cloud-icon.png";
  const deleteImage = "https://i.wlycdn.com/wealthy-home-page/partner-close-button.svg";

  const dropdownOptions = documentOptions.length ? documentOptions?.map((eachDeclaration) => (
    <>
      <EachOption
        onClick={() => {
          setSelectedOption(eachDeclaration);
          setSelectedOptionInfo(eachDeclaration?.text);
        }}
      >
        {parse(eachDeclaration?.render)}
      </EachOption>
    </>
  )) : [];

  return (
    <Wrapper>
      <Title>{heading}</Title>
      <SubHeader dangerouslySetInnerHTML={{__html: subHeading}} />
      {selectedOptionInfo ? <InfoTextAndImg>
        <OptionInfoText className="pulsate-anim">
          <InfoTextImg src={IMAGES.infoIconThemeColor} />
          {parse(selectedOptionInfo)}</OptionInfoText>
      </InfoTextAndImg>
        : <ExtraSpace />}
      {documentOptions.length ? <Dropdown
        options={dropdownOptions}
        title={selectedOption?.render ? parse(selectedOption?.render) : "Choose an option"}
      /> : null}
      <DropZoneContainer>
        <Dropzone
          onDrop={handleImageUpload}
          multiple={false}
          accept={{
            "image/png": [".png"],
            "image/jpeg": [".jpg", ".jpeg"],
            "application/pdf": [".pdf"]
          }}
        >
          {({getRootProps, getInputProps}) => (
            <section>
              <div {...getRootProps()}>
                <Container>
                  <UploadImage src={fileUploadImage} />
                </Container>
                <input {...getInputProps()} />
                <UploadMessage dangerouslySetInnerHTML={{__html: uploadMessage}} />
              </div>
            </section>
          )}
        </Dropzone>
      </DropZoneContainer>

      {allowPasswordProtected ? <>
        <AttentionContainer><span>Attention:</span> Please provide password in the below input if it is a password-protected document.</AttentionContainer>
        <PasswordInput
          placeholder="Type your password here..."
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      </> :
        <AttentionContainer><span>Attention:</span> Please do not upload password-protected documents.</AttentionContainer>
      }
      <AttentionContainer>Supported file formats include only png, jpg, jpeg and pdf</AttentionContainer>
      <ErrorMessage>{errorMessage}</ErrorMessage>
      <ImagesContainer>
        {
          uploadedFiles.map((eachFile) => (
            showAnimation ? (
              <Animation>
                <AnimationText>Uploading...</AnimationText>
              </Animation>
            ) : (
              <>
                <ImageCard>
                  <PreviewImage src={URL.createObjectURL(eachFile)} />
                  <DetailWrapper>
                    <Text>{eachFile.name}</Text>
                    <ImageSize>{eachFile.size * 0.001}Kb</ImageSize>
                  </DetailWrapper>
                  <ActionWrapper>
                    <DeleteWrapper onClick={() => handleDeleteUploadedFile(eachFile)}>
                      <DeleteIcon
                        alt="delete image"
                        src={deleteImage}
                      />
                    </DeleteWrapper>
                  </ActionWrapper>
                </ImageCard>
              </>
            )
          ))
        }
      </ImagesContainer>
      <ActionButton
        buttonText="Upload & Continue"
        callback={onSubmit}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
    </Wrapper>
  );
};

UploadDocument.propTypes = propTypes;

export default UploadDocument;
