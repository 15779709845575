/**
Author - Pradip Dhakal
Git - https://github.com/pradipwealthy
**/

import React, {
  useState
} from "react";
import {
  PropTypes
} from "prop-types";

import UploadDocumentContainer from "components/UploadDocument/UploadDocument.container";
import {
  uploadEuinCard
} from "api";
import {
  handleApiError
} from "helpers";


const propTypes = {
  updateStatus: PropTypes.func.isRequired,
};

const EuinCardUpload = ({
  updateStatus
}) => {

  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const handleSubmitFile = async (formData) => {
    setErrorMessage("");
    setIsUploading(true);
    try {
      const res = await uploadEuinCard(formData);
      if (res.status === 200) {
        updateStatus();
      }
      if (res.status === 400) {
        res.json().then(data => {
          setErrorMessage(data.detail);
        });
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsUploading(false);
    }

  };


  return( 
    <>
        <UploadDocumentContainer
          isLoading={isUploading}
          onSubmitDocument={handleSubmitFile}
          heading="EUIN Card Upload"
          subHeading="Please upload copy of your EUIN card."
          documentOptions={""}
          errorMessage={errorMessage}
        />
    </>
);};

EuinCardUpload.prototype = propTypes;
export default EuinCardUpload;
