/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React, {
  useEffect
} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import MetaForm from "@manojadams/metaforms";

const schema = {
  "theme": {
    "type": "mui",
    "mui": {
      "size": "medium",
      "variant": "standard",
    }
  },
  "fields": [
    {
      "name": "full_name",
      "meta": {
        "displayName": "Full Name",
        "displayType": "text_field",
        "isDisabled": false,
        "className": "input-container",
        "validation": {
          "required": true
        }
      }
    },
    {
      "name": "arn",
      "meta": {
        "displayName": "ARN",
        "className": "input-container",
        "validation": {
          "max": 16,
          "min": 16
        }
      }
    },
    {
      "name": "email",
      "meta": {
        "displayName": "Email",
        "className": "input-container",
        "validation": {
          "required": true,
          "pattern": "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
        }
      }
    },
    {
      "name": "address",
      "meta": {
        "displayName": "Address",
        "className": "input-container",
        "validation": {
          "required": true
        }
      }
    },
    {
      "name": "location",
      "meta": {
        "displayName": "Location",
        "className": "input-container",
        "validation": {
          "required": true
        }
      }
    },
  ],
  "buttons": [
    {
      "name": "next",
      "meta": {
        "type": "submit",
        "displayName": "Continue",
        "className": "action-btn",
        "validation": {
          "required": true
        }
      }
    }
  ]
};

const propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  formSchema: PropTypes.object.isRequired,
  skipStep: PropTypes.func.isRequired
};

const DocumentVerification = ({
  formSchema,
  handleFormSubmit,
  skipStep
}) => {
  useEffect(() => {
    const stepperContainer = document.querySelector('[class*="meta-form-stepper"]');

    if (!!stepperContainer) {
      stepperContainer.classList.add("stepper");
    }
  }, []);

  return (
    <CheckDocumentVerificationWrapper>
      <Container>
        <Wrapper>
          <Title>Document Verification</Title>
          <SubHeader>Please fill details below</SubHeader>
          <FormWrapper>
            <MetaForm
              schema={formSchema}
              onSubmit={(data) => {
                handleFormSubmit(data);
              }}
            />
          </FormWrapper>
          <ExtraSpace />
        </Wrapper>
      </Container>
    </CheckDocumentVerificationWrapper>
  );
};

const CheckDocumentVerificationWrapper = styled.div`
  height: 100vh;
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;
`;

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const HeaderText = styled.h1.attrs({
  className: 'caption'
})`
  margin: 0;
  padding: 0;
  color: #6725F4;
  letter-spacing: 0.1rem;
  align-self: end;
`;

const Wrapper = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  margin: 0 auto;
`;

const ExtraSpace = styled.div`
  height: 250px;
  margin: 10rem 0;
  opacity: 0;
`;

const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  letter-spacing: 0.1rem;
`;

const SubHeader = styled.p.attrs({
  className: 'description2'
})`
  margin: 1rem 0 0 0;
  padding: 0;
  color: #7E7E7E;
`;

const FormWrapper = styled.div`
  margin-top: 10%;

  .stepper {
    opacity: 0;
    display: none;
  }

  .input-container {
    margin-bottom: 3rem;
  }

  .input-container label {
    font-size: 1.4rem;
    font-weight: 400;
    color: #7E7E7E;
  }

  .input-container label+div {
    font-size: 1.6rem;
    font-weight: 400;
    color: #000000;
  }

  .input-container input {
    border: none;
    background: transparent;
    border-bottom: 1px solid #DDDDDD;
    outline: none;
    padding-bottom: 0.5rem;
    color: #000000;
    font-size: 1.6rem;
    font-family: MavenPro;
    font-weight: 500;
    width: 100%;
  }

  .action-btn {
    padding: 1.5rem 0;
    background: #6725F4;
    border-radius: 52px;
    width: 100%;
    margin-top: 3rem;
    color: #ffffff;
    font-size: 1.6rem;
    font-family: MavenPro;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: scale 300ms;

    :hover {
      background-color: #6725F4;
      scale: 1.02;
    }
  }
`;

DocumentVerification.propTypes = propTypes;

export default DocumentVerification;
