/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import {
  ActionButton,
  Spinner
} from "screens/commons";

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  skipStep: PropTypes.func.isRequired,
  agreementUrl: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showSkipButton: PropTypes.bool.isRequired
};

const PartnerAgreement = ({
  onSubmit,
  skipStep,
  agreementUrl,
  isLoading,
  showSkipButton
}) =>
(
  isLoading ? (
    <LoaderContainer>
      <Spinner
        color="blue"
        message="Updating your information, Please do not close the window"
      />
    </LoaderContainer>
  ) : (
    <PartnerAgreementWrapper>
      <Container>
        <Header>
          <Title>Agreement</Title>
          {
            showSkipButton ? (
              <HeaderText onClick={skipStep}>Skip</HeaderText>
            ) : (
              <span onClick={skipStep} class="cross-button" />
            )
          }
        </Header>
        <SubHeader>Please accept and sign the agreement</SubHeader>
        <PartnerAgreementContentWrapper>
          <iframe
            title="agreement"
            src={agreementUrl}
            width="100%"
            height="100%"
            style={{border: '1px #7E7E7E solid', borderRadius: '5px'}}
          />
        </PartnerAgreementContentWrapper>
        <ActionButton
          isDisabled={isLoading}
          buttonText="Accept & Sign"
          callback={onSubmit}
          additionalStyles={{marginBottom: '30%'}}
        />
      </Container>
    </PartnerAgreementWrapper>
  )
);

const PartnerAgreementWrapper = styled.div`
  height: 100vh;
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;
`;

const PartnerAgreementContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 2rem;
`;

const Container = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
`;

const LoaderContainer = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: start;
`;

const Title = styled.div.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  letter-spacing: 0.1rem;
  align-self: start;
`;

const HeaderText = styled.div.attrs({
  className: 'caption'
})`
  margin: 0;
  padding: 0;
  color: #6725F4;
  letter-spacing: 0.1rem;
  align-self: end;
  cursor: pointer;
`;

const SubHeader = styled.span.attrs({
  className: 'description2'
})`
  padding: 0;
  color: #7E7E7E;
  align-self: start;
`;

PartnerAgreement.propTypes = propTypes;

export default PartnerAgreement;