/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/


const dateFormatter = (date) => {
  if (date) {
    let formattedDate = date.split("/").reverse().join("-");
    return formattedDate;
  }
  return "";
};

export default dateFormatter;
