/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import React from "react";
import PropTypes from "prop-types";

import {
  ActionButton,
  CustomInput,
  Spinner
} from "screens/commons";

import {
  PartnerBankDetailsWrapper,
  Container,
  Title,
  SubHeader,
  InputsContainer,
  ErrorMessage,
  IfscAddress,
  Header,
  HeaderText,
  FormContent,
  LoaderContainer
} from './PartnerBankDetails.styles';

const propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isRedirectLoading: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isIfscValid: PropTypes.bool.isRequired,
  ifscResponse: PropTypes.object.isRequired,
  skipStep: PropTypes.func.isRequired,
  showSkipButton: PropTypes.object.isRequired
};

const PartnerBankDetails = ({
  onChangeHandler,
  onSubmit,
  formData,
  isLoading,
  isRedirectLoading,
  isDisabled,
  isIfscValid,
  ifscResponse,
  skipStep,
  showSkipButton
}) => (
  isRedirectLoading ? (
    <LoaderContainer>
      <Spinner
        color="blue"
        message="Updating your information, Please do not close the window"
      />
    </LoaderContainer>
  ) : (
    <PartnerBankDetailsWrapper>
      <Container>
        <FormContent>
          <Header>
            <Title>Bank Details</Title>
            {
              showSkipButton ? (
                <HeaderText onClick={skipStep}>Skip</HeaderText>
              ) : (
                <span onClick={skipStep} class="cross-button" />
              )
            }
          </Header>
          <SubHeader>Type in all your details correctly and recheck</SubHeader>
          <InputsContainer>
            <CustomInput
              label="Account Number"
              name="beneficiaryAccountNo"
              type="number"
              value={formData.beneficiaryAccountNo}
              onChange={onChangeHandler}
            />
            <CustomInput
              label="IFSC Code"
              name="beneficiaryAccountIfsc"
              value={formData.beneficiaryAccountIfsc}
              onChange={onChangeHandler}
            >
              {
                !isIfscValid ? (
                  <ErrorMessage>Invalid IFSC Code, please check.</ErrorMessage>
                ) : (
                  <IfscAddress>{ifscResponse.address}</IfscAddress>
                )
              }
            </CustomInput>
          </InputsContainer>
        </FormContent>
        <ActionButton
          isDisabled={isDisabled}
          buttonText="Save"
          callback={onSubmit}
          isLoading={isLoading}
        />
      </Container>
    </PartnerBankDetailsWrapper>
  ));

PartnerBankDetails.propTypes = propTypes;

export default PartnerBankDetails;
