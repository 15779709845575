/**
Author - Brijesh Pandey
Git - https://github.com/Brijesh-Pandey
**/

import React, {
useState, useEffect
} from "react";

import {
getFnoDeclarations,
skipFnoDeclarations
} from "api";
import {
  handleApiError
} from "helpers";

import {
  DeclarationList,
  UploadDocument,
  SkipFlow
} from "./components";

const FnoDeclarationsContainer = ({
  updateStatus
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [declarationsList, setDeclarationsList] = useState([]);
  const [step, updateStep] = useState(1);

  const [displayComponent, setDisplayComponent] = useState(null);

  useEffect(() => {
    getDeclarations();
  }, []);

  useEffect(() => {
    handleActiveComponent();
  }, [step, declarationsList]);

  const getDeclarations = async () => {
    try {
      setIsLoading(true);
      const res = await getFnoDeclarations();
      setDeclarationsList(res.data);
    } catch (error) {
      handleApiError(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleContinueClick = () => {
    updateStep(2);
  };

  const skipBrokingFlow = async () => {
    updateStep(0);
  };

  let activeComponent = (<DeclarationList
    isError={isError}
    isLoading={isLoading}
    handleContinueClick={handleContinueClick}
    declarationsList={declarationsList}
    skipBrokingFlow={skipBrokingFlow}
  />);

  const handleActiveComponent = () => {
    switch (step) {
      case 0:
        activeComponent = <SkipFlow />;
        break;
      case 1:
        activeComponent = <DeclarationList
          isError={isError}
          isLoading={isLoading}
          handleContinueClick={handleContinueClick}
          declarationsList={declarationsList}
          skipBrokingFlow={skipBrokingFlow}
        />;
        break;
      case 2:
        activeComponent = <UploadDocument
          declarationsList={declarationsList}
          updateStatus={updateStatus}
        />;
        break;

      default:
        activeComponent = <UploadDocument
          declarationsList={declarationsList}
          updateStatus={updateStatus}
        />;
        break;
    }
    setDisplayComponent(activeComponent);
  };

  return (
    <>
      {
        displayComponent === null ? (
          <DeclarationList
            isError={isError}
            isLoading={isLoading}
            handleContinueClick={handleContinueClick}
            declarationsList={declarationsList}
            skipBrokingFlow={skipBrokingFlow}
          />
        ) : (displayComponent)
      }
    </>
  );
};

export default FnoDeclarationsContainer;
