/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

const getAge = (date) => {
  let today = new Date();
  let birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  let month = today.getMonth() - birthDate.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
};

export default getAge;
