/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import {
  useEffect,
  useState
} from "react";

import {
  validateIfscCode
} from "api";
import {
  handleApiError
} from "helpers";

const useCheckIfscCode = (
  beneficiaryAccountIfsc,
  setIsLoading
) => {
  const [isIfscValid, setIsIfscValid] = useState(true);
  const [ifscResponse, setIfscResponse] = useState({});

  useEffect(() => {
    if (beneficiaryAccountIfsc?.length === 11) {
      checkIfscCode();
    }
  }, [beneficiaryAccountIfsc]);

  const checkIfscCode = async () => {
    try {
      setIsLoading(true);
      const res = await validateIfscCode(beneficiaryAccountIfsc);
      if (res.data?.verified) {
        setIfscResponse(res.data);
        setIsIfscValid(true);
      } else {
        setIsIfscValid(false);
      }
    } catch (error) {
      handleApiError(error);
      setIsIfscValid(false);
    } finally {
      setIsLoading(false);
    }
  };

  return [isIfscValid, ifscResponse];
};

export default useCheckIfscCode;
