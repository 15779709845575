/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

const getYearsBack = (customYear) => {
  const eighteenYearsAgo = new Date();
  const year = eighteenYearsAgo.getFullYear() - parseInt(customYear);
  let month = eighteenYearsAgo.getMonth() + 1;
  let day = eighteenYearsAgo.getDate();

  if (month.toString()?.length === 1) {
    month = `0${month}`;
  }
  if (day.toString()?.length === 1) {
    day = `0${day}`;
  }

  const customBackYear = `${year}-${month}-${day}`;
  return customBackYear;
};

export default getYearsBack;
