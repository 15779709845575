import React, {
 createContext
} from 'react';

import {
 sendEventsToClientApp
} from "../utils/ClientAppUtils";

const ClientAppCommunicationContext = createContext({});

class ClientAppCommsHandlers {
  subscriptions: ((eventName) => void)[] = [];

  handleClientAppEvents = (event) => {
    this.subscriptions.forEach((fn) => fn(event));
  };

  sendEventsToClientApp = (event) => {
    sendEventsToClientApp(event);
  };

  //TODO: need to support multiple event handlers
  subscribeToEvents = (handlerFn: (eventName) => void) => {
    if (window) {
      window.handleClientAppEvents = handlerFn;
    }
  };
}

export {
 ClientAppCommunicationContext, ClientAppCommsHandlers
};
