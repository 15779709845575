/**
Author - Venkatesh Pullaganti
Git - https://github.com/venkatesh1146
**/

import React, {
  useState, useEffect
} from "react";
import {
  PropTypes
} from "prop-types";

import UploadDocumentContainer from "components/UploadDocument/UploadDocument.container";
import {
  getBankProofMetaData,
  uploadBankProof
} from "api";
import {
  handleApiError
} from "helpers";
import {
  LoaderContainer, Spinner
} from "../commons";


const propTypes = {
  updateStatus: PropTypes.func.isRequired,
};

const BankProof = ({
  updateStatus
}) => {

  const [isUploading, setIsUploading] = useState(false);
  const [isFetchingMetaData, setIsFetchingMetaData] = useState(false);
  const [BankProofMetaData, setBankProofMetaData] = useState(null);
  const [errorMessage, setErrorMessage] = useState();

  const handleSubmitFile = async (formData, docType) => {
    setErrorMessage(false);
    setIsUploading(true);
    try {
      const res = await uploadBankProof(formData, docType);
      if (res.status === 200) {
        updateStatus();
      }
      else if(res.status === 400) {
        if (res.status === 400) {
          res.json().then(data => {
            setErrorMessage(data.detail);
          });
        }
      }
      else if (!res.ok) {

        throw {
          response: {
            status: 400,
            data: {
              detail: {
                message: "File Format Not Supported"
              }
            }
          }
        };
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsUploading(false);
    }

  };

  const fetchMetaData = async () => {
    setIsFetchingMetaData(true);
    try {
      const res = await getBankProofMetaData();
      setBankProofMetaData(res.data);
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsFetchingMetaData(false);
    }
  };

  useEffect(() => {
    fetchMetaData();
  }, []);


  return isFetchingMetaData ? (
    <LoaderContainer>
      <Spinner
        color="blue"
        message="Updating your information, Please do not close the window"
      />
    </LoaderContainer>
  ) :
    <>
      {BankProofMetaData ? (
        <UploadDocumentContainer
          isLoading={isUploading}
          onSubmitDocument={handleSubmitFile}
          heading={BankProofMetaData?.pageTitle}
          subHeading={BankProofMetaData?.cardTitle}
          documentOptions={BankProofMetaData?.allowedBankProofs}
          errorMessage = {errorMessage}
        />
      ) :
        null}
    </>;
};

BankProof.prototype = propTypes;
export default BankProof;
