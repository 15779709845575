/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import {
  createGlobalStyle
} from "styled-components";

import env from "env";

const GlobalStyles = createGlobalStyle`{
  @font-face {
    font-family: Lato;
    font-style: normal;
    src: url(${env.IS_PRODUCTION ? 'https://i.wlycdn.com/wealthy-kyc/lato-normal.woff' : 'https://ik.imagekit.io/trmma9w9c/lato-normal.woff?updatedAt=1687607582760'}) format("woff")
  }
  
  @font-face {
    font-family: Lato-Medium;
    src: url(${env.IS_PRODUCTION ? 'https://i.wlycdn.com/wealthy-kyc/lato-medium.woff' : 'https://ik.imagekit.io/trmma9w9c/lato-medium.woff?updatedAt=1687607582775'}) format("woff")
  }
  
  @font-face {
    font-family: Lato-SemiBold;
    font-style: semiBold;
    src: url(${env.IS_PRODUCTION ? 'https://i.wlycdn.com/wealthy-kyc/lato-semibold.woff' : 'https://ik.imagekit.io/trmma9w9c/lato-semibold.woff?updatedAt=1687607582753'}) format("woff")
  }
  
  @font-face {
    font-family: Lato-Bold;
    font-style: bold;
    src: url(${env.IS_PRODUCTION ? 'https://i.wlycdn.com/wealthy-kyc/lato-bold-2.woff' : 'https://ik.imagekit.io/trmma9w9c/lato-bold.woff?updatedAt=1687607582141'}) format("woff")
  }

  .cross-button {
    cursor: pointer;
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
    position: relative;
    width: 24px;
    height: 24px;
  
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 12px;
      left: 0;
      right: 0;
      height: 2px;
      background: #6725F4;
    }
  
    &:before {
      transform: rotate(45deg);
    }
  
    &:after {
      transform: rotate(-45deg);
    }
  
    span {
      display: block;
    }
  
  }
}`;

export default GlobalStyles;
