/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import React from "react";
import PropTypes from "prop-types";

import {
  ActionButton,
  CustomInput,
  Spinner
} from "screens/commons";

import {
  PartnerBankDetailsWrapper,
  ExtraSpace,
  Container,
  Title,
  SubHeader,
  InputsContainer,
  Header,
  LoaderContainer
} from './PartnerGst.styles';

const propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isRedirectLoading: PropTypes.bool.isRequired,
  skipStep: PropTypes.func.isRequired,
  showSkipButton: PropTypes.bool.isRequired
};

const PartnerGst = ({
  onChangeHandler,
  onSubmit,
  formData,
  isLoading,
  isRedirectLoading,
  skipStep,
  showSkipButton
}) => (
  isRedirectLoading ? (
    <LoaderContainer>
      <Spinner
        color="blue"
        message="Updating your information, Please do not close the window"
      />
    </LoaderContainer>
  ) : (
    <PartnerBankDetailsWrapper>
      <Container>
        <Header>
          <Title>Enter GST Number</Title>
          {
            showSkipButton ? (
              <SubHeader onClick={skipStep}>Skip</SubHeader>
            ) : (
              <span onClick={skipStep} class="cross-button" />
            )
          }
        </Header>
        <InputsContainer>
          <CustomInput
            label="Your GST Number"
            name="gstNo"
            type="text"
            value={formData.gstNo}
            onChange={onChangeHandler}
          />
          <ActionButton
            isDisabled={isLoading}
            buttonText="Proceed"
            callback={onSubmit}
          />
        </InputsContainer>
        <ExtraSpace />
      </Container>
    </PartnerBankDetailsWrapper>
  ));

PartnerGst.prototype = propTypes;

export default PartnerGst;
