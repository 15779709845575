/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React from "react";
import styled from "styled-components";

const successScreenImage = "https://i.wlycdn.com/wealthy-home-page/application-success-screen-kyc.svg";

const defaultData = {
  title: "Application submitted successfully",
  content: "You can come back and complete your F&O setup anytime. For now, you can click on the Close button to close this flow and continue investing.",
  ctaText: ""
};

const proposalLinkPresentData = {
  title: "Application submitted successfully",
  content: "You can come back and complete your F&O setup anytime."
};

const SkipFlow = ({
  proposalLink,
  timer
}) => {
  const activeContent = proposalLink?.length > 0 ? proposalLinkPresentData : defaultData;

  return (
    <SuccessScreenWrapper>
    <Container>
      <DataContainer>
        <ImageContainer>
          <img alt="connect-with-wealth-partner" src={successScreenImage} />
        </ImageContainer>
        <Title>{activeContent.title}</Title>
        <SubHeader>
          {activeContent.content}
        </SubHeader>
        {
          proposalLink?.length > 0 ? (
            <SuccessText>
              {
                proposalLink?.length && proposalLink !== "null" ? (
                  `Redirecting you in ${timer} seconds`
                ) : (
                  "Now you can close the browser to go back to the app."
                )
              }
            </SuccessText>
          ) : (null)
        }
      </DataContainer>
    </Container>
  </SuccessScreenWrapper>
  );

};

const SuccessScreenWrapper = styled.div`
  height: 100vh;
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;

  @media (max-width: 768px) {
    background:#fff;
  }
`;

const Container = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  margin: 0 auto;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ImageContainer = styled.div``;

const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  color: #000000;
  margin-top: 3.6rem;
  line-height: 1.4;
  letter-spacing: 0.1rem;
`;

const SubHeader = styled.p.attrs({
  className: 'description2'
})`
  margin: 1rem 0 0 0;
  padding: 0;
  text-align: center;
  color: #7E7E7E;
  margin-top: 1.6rem;
  max-width: 90%;
  :not(:first-child) {
    margin-top: 3rem;
  }
`;

const SuccessText = styled(SubHeader)``;

export default SkipFlow;
